import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import {
  markNotificationsReadForCurrentUser,
  Notification,
} from '@app/api/resources/Notifications';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import ClickOutside from '@app/components/ClickOutside';
import NavigationMenuAnimation from '@app/components/layout/notifications/NavigationMenuAnimation';
import NotificationMenu from '@app/components/layout/notifications/NotificationMenu';

const NotificationDot = ({
  notifications = [],
}: {
  notifications: Notification[];
}) => {
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [numUnreadNotifications, setNumUnreadNotifications] = useState(0);
  const httpContext = useAppSelector(state => state.appState.httpContext);

  const trackSnowplowClickEvent = useSnowplowClickTracker();

  useEffect(() => {
    doSetNumUnreadNotifications();
  }, [notifications?.length]);

  const doSetNumUnreadNotifications = () => {
    const newNumUnreadNotifications =
      notifications?.reduce((acc, curr) => {
        if (!curr.read) {
          return acc + 1;
        }
        return acc;
      }, 0) || 0;

    setNumUnreadNotifications(newNumUnreadNotifications);
  };

  const doShowNotificationMenu = () => {
    if (notifications?.length > 0) {
      markNotificationsReadForCurrentUser(httpContext);
    }
    trackSnowplowClickEvent({ data: { clickType: 'notification_dot' } });
    setShowNotificationMenu(true);
  };

  const clickOutsideNav = e => {
    if (showNotificationMenu) {
      e.stopPropagation();
      e.preventDefault();
      setShowNotificationMenu(false);
    }
  };

  return (
    <>
      <DotContainer>
        <DotClick onClick={doShowNotificationMenu}>
          <Dot hasMessages={numUnreadNotifications > 0} />
        </DotClick>
        <NotificationContainer>
          <NavigationMenuAnimation isShowing={showNotificationMenu}>
            <ClickOutside
              onClickOutside={clickOutsideNav}
              active={showNotificationMenu}
            >
              <NotificationMenu notifications={notifications} />
            </ClickOutside>
          </NavigationMenuAnimation>
        </NotificationContainer>
      </DotContainer>
    </>
  );
};

const DotContainer = styled.div`
  height: 100%;
  width: 40px;
  position: relative;
  z-index: 4;
  display: inline-flex;
  align-items: center;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 10px;
    display: block;
  }
`;

const DotClick = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: auto;
    display: block;
  }
`;

const Dot = styled.div<{ hasMessages: boolean }>`
  height: 12px;
  width: 12px;
  background-color: ${props =>
    props.hasMessages
      ? props.theme.color.mubiBlue
      : props.theme.color.lightGray};
  border-radius: 50%;

  cursor: pointer;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 10px;
    width: 10px;
    margin-top: 11px;
  }
`;

const NotificationContainer = styled.div`
  position: absolute;
  top: 83px;
  right: -17px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    top: 69px;
  }
`;

export default NotificationDot;
