import { assetsDomain } from '@app/api/fixtures/fixture-domains';
import { Notification } from '@app/api/resources/Notifications';

export const someNotifications: Notification[] = [
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">Taste of Cherry</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/242/image-w80.jpg?1575590423`,
    created_at: 1575914402,
    path: '/films/242/player',
    preview: null,
    read: false,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">Three Colors: Blue</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/414/image-w80.jpg?1574857174`,
    created_at: 1575828001,
    path: '/films/414/player',
    preview: null,
    read: false,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">Breathless</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/268/image-w80.jpg?1564977602`,
    created_at: 1575396001,
    path: '/films/268/player',
    preview: null,
    read: true,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">Bixa Travesty</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/197156/image-w80.jpg?1574409697`,
    created_at: 1574791208,
    path: '/films/197156/player',
    preview: null,
    read: false,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">Last Year at Marienbad</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/1517/image-w80.jpg?1575331226`,
    created_at: 1573668001,
    path: '/films/1517/player',
    preview: null,
    read: false,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'film_showing',
    body_html:
      '<div class=\'notification-header\'>\n4 STAR FILM ALERT!\n</div>\n<span class="notification-publisher" lang="en">A Paper Tiger</span> is getting great ratings. <span class="notification-prompt">Watch now.</span>\n',
    image_url: `${assetsDomain}/images/film/126143/image-w80.jpg?1573272003`,
    created_at: 1573495202,
    path: '/films/126143/player',
    preview: null,
    read: false,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'commented_on_list',
    body_html:
      "Yoon Kyuubi also commented on Yoon Kyuubi's list, watch in 2019.\n",
    image_url:
      'https://graph.facebook.com/2076108429067787/picture?width=80&height=80',
    created_at: 1572948669,
    path: '/lists/watch-in-2019',
    preview: 'McQueen',
    read: true,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'commented_on_list',
    body_html: 'Yoon Kyuubi commented on your list, watch in 2019.\n',
    image_url:
      'https://graph.facebook.com/2076108429067787/picture?width=80&height=80',
    created_at: 1572948669,
    path: '/lists/watch-in-2019',
    preview: 'McQueen',
    read: true,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'liked_rating',
    body_html:
      'efe liked your rating of <span class="notification-publisher" lang="en">American Hustle</span>.\n',
    image_url: `${assetsDomain}/images/avatars/13/images-w80.jpg?1523882987`,
    created_at: 1390423343,
    path: '/films/american-hustle/ratings/10065777',
    preview: null,
    read: true,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
  {
    type: 'followed_list',
    body_html:
      'ryan started following your list, <span class="notification-publisher" lang="en">Films that made your kid sister cry</span>.\n',
    image_url: `${assetsDomain}/images/avatars/6424/images-w80.jpg?1523884512`,
    created_at: 1257897885,
    path: '/lists/films-that-made-your-kid-sister-cry/followers',
    preview: null,
    read: true,
    prompt_subscription: false,
    recipient_scope: 'self',
  },
];
