import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { setUserFeedbackBanner } from '@app/actions/BannerActions';

const FADEOUT_DELAY = 5000;

const useShowFeedbackBanner = (
  message: string,
  autodismiss: boolean,
  id: number,
): [boolean, boolean, () => void] => {
  const [showBanner, setShowBanner] = useState(false);
  const [shouldShowElement, setShouldShowElement] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const hasMessage = message.length > 0;
    setShowBanner(hasMessage);
    setShouldShowElement(hasMessage);

    let fadeTimeoutId: NodeJS.Timeout = null;

    if (autodismiss) {
      fadeTimeoutId = setTimeout(() => dismissBanner(), FADEOUT_DELAY);
    }

    return () => {
      clearTimeout(fadeTimeoutId);
    };
  }, [message, id]);

  useEffect(() => {
    router.events.on('routeChangeStart', dismissBanner);

    return () => {
      router.events.off('routeChangeStart', dismissBanner);
    };
  }, []);

  const dispatch = useDispatch();

  const dismissBanner = () => {
    setShowBanner(false);
    setTimeout(() => {
      setShouldShowElement(false);
      dispatch(setUserFeedbackBanner(''));
    }, 300);
  };

  return [showBanner, shouldShowElement, dismissBanner];
};

export default useShowFeedbackBanner;
