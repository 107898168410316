import { css } from '@emotion/react';

export const SearchInputDesktop = props => css`
  height: 50px;
  padding: 24px 15px 12px;
  padding-left: 42px;
  font-size: 14px;
  line-height: 14px;
  background-color: ${props.theme.color.white};
`;

export const SearchInputTransparent = props => css`
  ${props.keepFocused
    ? css`
        background-color: ${props.theme.color.rgbaWhite60Opacity};
        ::placeholder {
          color: ${props.theme.color.white};
        }
      `
    : css`
        background-color: transparent;

        ::placeholder {
          color: ${props.theme.color.rgbaWhite60Opacity};
        }
      `};

  color: ${props.theme.color.darkText};
`;

export const SearchInputDefault = props => css`
  ${props.keepFocused
    ? css`
        background-color: ${props.theme.color.lightBackground};
        ::placeholder {
          color: ${props.theme.color.lightGray};
        }
      `
    : css``};
`;

export const SearchInputBaseStyle = props => css`
  font-family: ${props.theme.font.title};
  font-weight: 500;
  font-size: 20px;
  display: block;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  color: ${props.theme.color.darkGray};
  position: relative;
  z-index: 2;
  background: transparent;
  height: 60px;
  padding-left: 49px;
  padding-right: 38px;
  outline: none;
  ::placeholder {
    ${props.theme.font.unantialias}
  }
`;
