import { FilmSearchResult } from '@app/api/services/search';

import NavSearchPictureResult from '@app/components/layout/nav-search/NavSearchPictureResult';

const NavSearchFilmResult = ({
  filmResult,
}: {
  filmResult: FilmSearchResult;
}) => {
  const getFilmDirectorName = (film: FilmSearchResult) =>
    film?.directors[0]?.name ?? '';

  const getFilmStill = (film: FilmSearchResult) => film?.stills?.square ?? '';

  return (
    <NavSearchPictureResult
      imageUrl={getFilmStill(filmResult)}
      title={filmResult.title}
      subtitle={getFilmDirectorName(filmResult)}
      resultUrl={filmResult.canonical_url}
      showPlay={filmResult.playable}
    />
  );
};

export default NavSearchFilmResult;
