import styled from '@emotion/styled';

import UnstyledButton from '@app/components/buttons/UnstyledButton';

const MenuLinkButton = styled(UnstyledButton)`
  color: ${props => props.theme.color.darkGray};
  display: block;
  padding: 0px 25px;
  font-size: 20px;
  line-height: 42px;

  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  ${props => (props.isUppercase ? 'text-transform: uppercase;' : '')};

  &:hover {
    color: ${props => props.theme.color.darkText};
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
`;

export default MenuLinkButton;
