import { useEffect, useState } from 'react';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useBrowseFilmsUrl = (): string => {
  const [browseFilmsUrl, setBrowseFilmsUrl] = useState('/films');

  const filterBrowseByNowShowing = useAppSelector(
    state => state.appState.filterBrowseFilmsByNowShowing,
  );

  useEffect(() => {
    if (filterBrowseByNowShowing) {
      setBrowseFilmsUrl('/films?filterShowing=true');
    } else {
      setBrowseFilmsUrl('/films');
    }
  }, [filterBrowseByNowShowing]);

  return browseFilmsUrl;
};

export default useBrowseFilmsUrl;
