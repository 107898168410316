import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  doSearch,
  getResultsFromResponseData,
  getResultTotalCountsFromResponse,
  initialSearchMeta,
  initialSearchResults,
  SearchResponse,
} from '@app/api/services/search';
import { gtmVirtualPageView } from '@app/services/gtm';
import {
  checkPathnamesAreEqual,
  getFullRouteUrl,
} from '@app/services/routeHelpers';

import { color as themeColor } from '@app/themes/mubi-theme';

import useGetSearchSuggestions from '@app/hooks/fetch-data/useGetSearchSuggestions';
import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useSnowplowQuickSearchSuggestionsTracking from '@app/hooks/snowplow/useSnowplowQuickSearchSuggestionsTracking';
import useAppSelector from '@app/hooks/utils/useAppSelector';
import useDebounce from '@app/hooks/utils/useDebounce';

import ClickOutside from '@app/components/ClickOutside';
import {
  CloseIcon,
  LoadingIcon,
  SearchIcon,
} from '@app/components/icons/CommonIcons';
import { HeaderVariant } from '@app/components/layout/HeaderContainer';
import {
  SearchInputBaseStyle,
  SearchInputDefault,
  SearchInputDesktop,
  SearchInputTransparent,
} from '@app/components/layout/nav-search/NavSearch';
import NavSearchNoFilmResult from '@app/components/layout/nav-search/NavSearchNoFilmResult';
import NavSearchResults from '@app/components/layout/nav-search/NavSearchResults';
import NavSearchResultsSeeAll from '@app/components/layout/nav-search/NavSearchResultsSeeAll';
import NavSearchSuggestionsResults from '@app/components/layout/nav-search/NavSearchSuggestionsResults';

type Version = 'mobile' | 'desktop';

const MIN_SEARCH_CHAR = 3;

type NavSearchFormProps = {
  version?: Version;
  variant: HeaderVariant;
  isInputFocused?: boolean;
  setContainerInputFocus?: (shouldFocus: boolean) => void;
};

const NavSearchForm = ({
  version = 'mobile',
  isInputFocused = false,
  variant = 'default',
  setContainerInputFocus,
}: NavSearchFormProps) => {
  const { t } = useTranslation('common');
  const httpContext = useAppSelector(state => state.appState.httpContext);
  const i18nUrlData = useI18nUrlData();
  const router = useRouter();
  const { query } = router;
  const currentSearchTerm = query?.query;

  const [searchText, setSearchText] = useState('');
  const [doingSearch, setDoingSearch] = useState(false);
  const [searchResults, setSearchResults] =
    useState<typeof initialSearchResults>(initialSearchResults);
  const [searchMeta, setSearchMeta] = useState(initialSearchMeta);
  const [hideResults, setHideResults] = useState(true);
  const [showCurrentSearchTerm, setShowCurrentSearchTerm] = useState(
    typeof currentSearchTerm === 'string' && currentSearchTerm.length > 0,
  );

  const debouncedSearchText = useDebounce(searchText, 500);

  const textInput = useRef(null);

  const [searchSuggestions] = useGetSearchSuggestions();
  const trackSnowplowQuickSearchSuggestionsEvent =
    useSnowplowQuickSearchSuggestionsTracking();

  useEffect(() => {
    if (setContainerInputFocus) {
      setContainerInputFocus(false);
    }

    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  useEffect(() => {
    runSearch();
    if (setContainerInputFocus) {
      setContainerInputFocus(false);
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    if (isInputFocused) {
      textInput.current.focus();
    }
  }, [isInputFocused]);

  const onWindowBlur = useCallback(() => {
    if (hideResults) {
      clearSearch();
    }
  }, []);

  const runSearch = async () => {
    if (searchText.length > 0 && !doingSearch) {
      setDoingSearch(true);

      try {
        const response = await doSearch({
          httpContext,
          query: searchText,
          filterByNowShowing: true,
        });
        if (response.status === 200) {
          setSearch(response.data);
          trackSearchInGoogleAnalytics(searchText);
        } else {
          clearSearch();
        }
      } catch {
        clearSearch();
      }
    }

    if (searchText.length === 0 && !doingSearch) {
      setSearchText('');
      setSearchResults(initialSearchResults);
      setSearchMeta(initialSearchMeta);
    }
  };

  const trackSearchInGoogleAnalytics = (searchTextToTrack: string) => {
    if (searchTextToTrack.length >= MIN_SEARCH_CHAR) {
      gtmVirtualPageView(
        getFullRouteUrl({
          url: `/search/films?query=${searchTextToTrack}`,
          i18nUrlData,
          includeDomain: false,
        }),
        window.location.href,
      );
    }
  };

  const setSearch = async (data: SearchResponse) => {
    setSearchResults(getResultsFromResponseData(data));
    setSearchMeta(getResultTotalCountsFromResponse(data));
    setDoingSearch(false);
  };

  const clearSearch = () => {
    setSearchText('');
    setSearchResults(initialSearchResults);
    setSearchMeta(initialSearchMeta);
    setHideResults(true);
  };

  const handleClickOutside = () => {
    setHideResults(true);
    setContainerInputFocus(false);
  };

  const showClearSearchTextButton = () => {
    if (!hideResults && !doingSearch) {
      return true;
    }
    return false;
  };

  const onSearchTextChange = e => setSearchText(e.target.value);
  const onSearchTextFocus = () => {
    setShowCurrentSearchTerm(false);
    setHideResults(false);
    setContainerInputFocus(true);
  };

  const areThereResultsToShow = () => {
    if (
      searchMeta.filmTotalCount > 0 ||
      searchMeta.castMemberTotalCount > 0 ||
      searchMeta.collectionTotalCount > 0
    ) {
      return true;
    }
    return false;
  };

  const thereAreResultsToShow = areThereResultsToShow();

  const doSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHideResults(true);
    const { pathname, replace, push, query } = router;
    const { ...newQuery } = query;

    // Reset page if the search query has changed
    if (newQuery?.query !== searchText) {
      delete newQuery.page;
    }

    if (checkPathnamesAreEqual(pathname, '/search/[searchType]')) {
      replace({
        pathname: router.pathname,
        query: {
          ...newQuery,
          query: searchText,
        },
      });
    } else {
      push(`/search/films?query=${searchText}`);
    }
  };

  const showSuggestions =
    !hideResults &&
    searchSuggestions &&
    searchSuggestions.length > 0 &&
    !thereAreResultsToShow;

  return (
    <ClickOutside onClickOutside={handleClickOutside} active={!hideResults}>
      {!hideResults && <NavSearchOverlay onClick={clearSearch} />}
      <SearchForm
        addBorder={!hideResults && thereAreResultsToShow}
        version={version}
        onSubmit={doSubmit}
      >
        <SearchFormInputContainer>
          {showCurrentSearchTerm && (
            <CurrentSearchTerm>{currentSearchTerm}</CurrentSearchTerm>
          )}

          <SearchIconContainer version={version}>
            <SearchIcon
              width="100%"
              color={
                variant !== 'default' ? themeColor.white : themeColor.darkGray
              }
            />
          </SearchIconContainer>
          {doingSearch && (
            <LoadingIconContainer version={version}>
              <LoadingIcon
                width="100%"
                color={
                  variant !== 'default'
                    ? themeColor.white
                    : themeColor.lightGray
                }
              />
            </LoadingIconContainer>
          )}
          {showClearSearchTextButton() && (
            <ClearSearchTextIconContainer
              onClick={clearSearch}
              version={version}
            >
              <CloseIcon
                width="100%"
                color={
                  variant !== 'default' ? themeColor.white : themeColor.darkGray
                }
              />
            </ClearSearchTextIconContainer>
          )}
          <SearchInput
            value={searchText}
            onChange={onSearchTextChange}
            onFocus={onSearchTextFocus}
            name="query"
            autoComplete="off"
            placeholder={t('common:common.header_nav.search_placeholder')}
            type="text"
            version={version}
            variant={variant}
            keepFocused={!hideResults}
            ref={textInput}
            onBlur={() => {
              if (setContainerInputFocus) {
                setContainerInputFocus(false);
              }
            }}
          />
        </SearchFormInputContainer>

        {showSuggestions && (
          <InView
            onChange={inView => {
              if (inView) {
                trackSnowplowQuickSearchSuggestionsEvent({
                  suggestions: searchSuggestions,
                });
              }
            }}
          >
            <NavSearchResultsContainer>
              {debouncedSearchText.length !== 0 && !doingSearch && (
                <NavSearchNoFilmResult />
              )}
              <NavSearchSuggestionsResults
                searchSuggestionsResults={searchSuggestions}
              />
              {debouncedSearchText.length !== 0 && !doingSearch && (
                <NavSearchResultsSeeAll
                  searchText={searchText}
                  onShowAll={() => setHideResults(true)}
                />
              )}
            </NavSearchResultsContainer>
          </InView>
        )}

        {!hideResults && thereAreResultsToShow && (
          <NavSearchResultsContainer>
            <NavSearchResults
              searchText={searchText}
              searchResults={searchResults}
              searchMeta={searchMeta}
              onShowAll={() => setHideResults(true)}
              setHideResults={setHideResults}
            />
          </NavSearchResultsContainer>
        )}
      </SearchForm>
    </ClickOutside>
  );
};

const SearchFormDesktop = props => css`
  border-bottom: none;
  width: 270px;
  height: 50px;

  @media (min-width: ${props.theme.mqNew.wide}) {
    width: 350px;
  }
`;

const SearchForm = styled.form<{ version: Version; addBorder: boolean }>`
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: ${props =>
    props.addBorder ? '0 -1px 8px rgba(0, 0, 0, 0.13)' : ''};
  border-bottom: 1px solid ${props => props.theme.color.midBackground};

  ${props => (props.version === 'desktop' ? SearchFormDesktop : '')}
`;

const SearchFormInputContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`;

const SearchInput = styled.input<{
  version: Version;
  variant: HeaderVariant;
  keepFocused?: boolean;
}>`
  ${SearchInputBaseStyle}

  ${props => (props.version === 'desktop' ? SearchInputDesktop : '')}
  ${props =>
    props.variant !== 'default' ? SearchInputTransparent : SearchInputDefault}
`;

const SearchIconContainerDesktop = css`
  top: calc(50% - 3px);
  width: 16px;
  height: 16px;
`;

const SearchIconContainer = styled.div<{ version: Version }>`
  position: absolute;
  top: calc(50% - 11px);
  left: 21px;
  z-index: 3;
  width: 22px;
  height: 22px;

  ${props => (props.version === 'desktop' ? SearchIconContainerDesktop : '')}
`;

const LoadingIconContainerDesktop = css`
  top: calc(50% - 3px);
  width: 16px;
  height: 16px;
`;

const LoadingIconContainer = styled.div<{ version: Version }>`
  position: absolute;
  top: calc(50% - 11px);
  right: 17px;
  z-index: 3;
  width: 21px;
  height: 21px;
  ${props => (props.version === 'desktop' ? LoadingIconContainerDesktop : '')}
`;

const ClearSearchTextIconContainerDesktop = css`
  top: calc(50% - 3px);
  width: 15px;
`;

const ClearSearchTextIconContainer = styled.div<{ version: Version }>`
  position: absolute;
  top: calc(50% - 11px);
  right: 17px;
  z-index: 3;
  width: 21px;
  cursor: pointer;

  ${props =>
    props.version === 'desktop' ? ClearSearchTextIconContainerDesktop : ''}
`;

const NavSearchResultsContainer = styled.div`
  z-index: 4;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  background-color: ${props => props.theme.color.white};
`;

const CurrentSearchTerm = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: inline-block;
    font-family: ${props => props.theme.font.title};
    font-weight: 500;
    padding: 6px 38px 0 42px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: 0;
    background: ${props => props.theme.color.lightBackground};
    pointer-events: none;
    font-size: 14px;
    line-height: 14px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    line-height: 45px;
    z-index: 3;
    color: ${props => props.theme.color.darkGray};
    height: 50px;
    padding-left: 42px;
    align-items: center;
  }
`;

const NavSearchOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default NavSearchForm;
