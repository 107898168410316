import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { getFilmStillUrlForExperiment } from '@app/api/services/experiments';

import useSnowplowQuickSearchTracking from '@app/hooks/snowplow/useSnowplowQuickSearchTracking';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import NavSearchPictureResult from '@app/components/layout/nav-search/NavSearchPictureResult';
import { SearchResultTitle } from '@app/components/layout/nav-search/NavSearchResults';

type NavSearchSuggestionsResultsProps = {
  searchSuggestionsResults?: Film[];
};
const NavSearchSuggestionsResults = ({
  searchSuggestionsResults = [],
}: NavSearchSuggestionsResultsProps) => {
  const { t } = useTranslation('common');
  const doTrackSnowplowQuickSearchEvent = useSnowplowQuickSearchTracking();
  const experiments = useAppSelector(state => state?.experiments?.experiments);
  const getFilmDirectorName = (film: Film) => film?.directors[0]?.name ?? '';

  return (
    <Container>
      <SearchResultTitle>
        {t('common:common.header_nav.suggested_films')}
      </SearchResultTitle>
      {searchSuggestionsResults?.map(searchSuggestion => {
        const filmStillImageUrl = getFilmStillUrlForExperiment(
          experiments,
          searchSuggestion,
        );
        const imageUrl =
          searchSuggestion?.stills?.square ||
          searchSuggestion?.stills?.small ||
          filmStillImageUrl;

        return (
          <div
            key={`film-suggestion-${searchSuggestion?.id}`}
            onClick={() => {
              doTrackSnowplowQuickSearchEvent(
                {
                  entity_id: searchSuggestion?.id,
                  sub_page: 'popular_film_searches',
                  click_type: 'film_suggestion',
                },
                {
                  film_id: searchSuggestion?.id,
                },
              );
            }}
          >
            <NavSearchPictureResult
              imageUrl={imageUrl}
              title={searchSuggestion?.title}
              subtitle={getFilmDirectorName(searchSuggestion)}
              resultUrl={searchSuggestion?.web_url}
              showPlay
            />
          </div>
        );
      })}
    </Container>
  );
};

export default NavSearchSuggestionsResults;

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding-bottom: 12px;
  overflow: hidden;
`;
