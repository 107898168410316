import { SyntheticEvent } from 'react';
import { shallowEqual } from 'react-redux';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import MenuLinks from '@app/components/layout/menu-links/MenuLinks';

type MenuLinksContainerProps = {
  closeMenu: (e?: SyntheticEvent) => void;
  showMainLinksOnDesktop?: boolean;
};

const MenuLinksContainer = ({
  closeMenu,
  showMainLinksOnDesktop,
}: MenuLinksContainerProps) => {
  const {
    userIsAuthenticated,
    userIsActiveSubscriber,
    userIsAdmin,
    userId,
    geoLocation = 'GB',
    currentLanguage,
  } = useAppSelector(
    state => ({
      userIsAuthenticated: state.user.isAuthenticated,
      userIsActiveSubscriber: state.user.activeSubscriber,
      userIsAdmin: state.user.isAdmin,
      userId: state?.user?.user?.id ?? null,
      geoLocation: state.user.geoLocation,
      currentLanguage: state.user.currentLanguage,
    }),
    shallowEqual,
  );

  return (
    <MenuLinks
      userIsAuthenticated={userIsAuthenticated}
      userIsActiveSubscriber={userIsActiveSubscriber}
      userIsAdmin={userIsAdmin}
      userId={userId}
      geoLocation={geoLocation}
      closeMenu={closeMenu}
      currentLanguage={currentLanguage}
      showMainLinksOnDesktop={showMainLinksOnDesktop}
    />
  );
};

export default MenuLinksContainer;
