import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Link from '@app/components/Link';

const sharedStyles = props => css`
  color: ${props.theme.color.darkGray};
  display: block;
  padding: 0px 25px;
  font-size: 20px;
  line-height: 42px;

  font-weight: ${props.isBold ? 'bold' : 'normal'};
  ${props.isUppercase ? 'text-transform: uppercase;' : ''};

  &:visited {
    color: ${props.theme.color.darkGray};
  }
  &:hover {
    color: ${props.theme.color.darkText};
    text-decoration: none;
  }

  @media (min-width: ${props.theme.mqNew.desktop}) {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
`;

const MenuLink = styled(Link)<{
  isBold?: boolean;
  isUppercase?: boolean;
}>`
  ${sharedStyles};
`;

const MenuHardLink = styled.a<{ isBold?: boolean; isUppercase?: boolean }>`
  ${sharedStyles};
`;

export { MenuLink, MenuHardLink };
