import { someNotifications } from '@app/api/fixtures/notifications';
import HttpService from '@app/api/http-service';
import { ObjectOfStrings } from '@app/api/utility-types';

export type Notification = {
  type: string;
  body_html: string;
  image_url: string;
  created_at: number;
  path: string;
  preview: string;
  read: boolean;
  prompt_subscription: boolean;
  recipient_scope: string;
};

export const getNotificationsForUser = (
  httpContext: ObjectOfStrings,
): Promise<{ data: Notification[] }> => {
  if (process.env.NODE_ENV === 'development') {
    return new Promise(() => ({
      data: someNotifications,
    }));
  }
  return HttpService(httpContext).get('/notifications');
};

export const markNotificationsReadForCurrentUser = (
  httpContext: ObjectOfStrings,
): Promise<{ status: 200 }> =>
  HttpService(httpContext).put('/notifications/mark_read');
