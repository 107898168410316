import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

const NavigationMenuAnimation = ({
  isShowing,
  children,
}: {
  isShowing: boolean;
  children: JSX.Element | JSX.Element[];
}) => (
  <CSSTransition in={isShowing} classNames="appear" timeout={200}>
    <Container>{children}</Container>
  </CSSTransition>
);

const Container = styled.div`
  display: none;
  transition: opacity 0.2s, transform 0.2s;
  &.appear-enter {
    display: block;
    opacity: 0;
    transform: translateY(-20px);
  }
  &.appear-enter.appear-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.appear-enter-done {
    display: block;
  }
  &.appear-exit {
    display: block;
    transform: translateY(0px);
    opacity: 1;
  }
  &.appear-exit.appear-exit-active {
    display: block;
    transform: translateY(-20px);
    opacity: 0;
  }
`;

export default NavigationMenuAnimation;
