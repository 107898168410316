import styled from '@emotion/styled';

const MenuItem = styled.li`
  padding: 0;
  font-family: ${props => props.theme.font.brand};
  display: block;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-family: ${props => props.theme.font.body};
    padding: 0 15px;
  }
`;

export default MenuItem;
