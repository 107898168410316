import {
  checkPathnameArrayIncludesPathname,
  checkPathnamesAreEqual,
} from '@app/services/routeHelpers';

export const isLayoutNavItemSelected = (
  currentRoute: string,
  navItem: string,
) => {
  if (navItem === 'notebook') {
    return checkPathnameArrayIncludesPathname(
      [
        '/notebook',
        '/notebook/posts/[post_slug]',
        '/notebook/posts/author/[author_id]',
        '/notebook/posts/tag/[tag_slug]',
        '/notebook/contributors',
      ],
      currentRoute,
    );
  }
  if (navItem === 'referrals_terms') {
    return checkPathnamesAreEqual(currentRoute, '/referrals/terms');
  }
  if (navItem === 'showing') {
    return checkPathnamesAreEqual(currentRoute, '/showing');
  }
  if (navItem === 'vision') {
    return checkPathnamesAreEqual(currentRoute, '/vision');
  }
  if (navItem === 'memberships') {
    return checkPathnamesAreEqual(currentRoute, '/memberships');
  }
  if (navItem === 'contact') {
    return checkPathnamesAreEqual(currentRoute, '/contact');
  }
  if (navItem === 'press') {
    return checkPathnamesAreEqual(currentRoute, '/press-new');
  }
  if (navItem === 'terms-of-service') {
    return checkPathnamesAreEqual(currentRoute, '/terms_of_service');
  }
  if (navItem === 'notebook-terms') {
    return checkPathnamesAreEqual(currentRoute, '/notebook/terms');
  }
  if (navItem === 'mubi-go-terms-of-service') {
    return checkPathnamesAreEqual(currentRoute, '/mubi_go_terms_of_service');
  }
  if (navItem === 'privacy-policy') {
    return checkPathnamesAreEqual(currentRoute, '/privacy_policy');
  }
  if (navItem === 'cookie_prefs') {
    return (
      checkPathnamesAreEqual(currentRoute, '/cookie_preferences') ||
      checkPathnamesAreEqual(currentRoute, '/cookie_preferences_tr')
    );
  }
  if (navItem === 'data_transfer_consent') {
    return checkPathnamesAreEqual(currentRoute, '/data_transfer_consent');
  }
  if (navItem === 'email_preferences') {
    return checkPathnamesAreEqual(currentRoute, '/email_preference_terms');
  }
  if (navItem === 'watchlist') {
    return checkPathnamesAreEqual(currentRoute, '/watchlist');
  }
  return false;
};
