import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { isLayoutNavItemSelected } from '@app/services/layout-nav';
import { getHeaderColor } from '@app/services/utils';

import { color as themeColors, ColorHex } from '@app/themes/mubi-theme';

import { useGetGoAvailabilityForCountry } from '@app/hooks/helpers/useGetGoAvailabilityForCountry';
import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import ClickOutside from '@app/components/ClickOutside';
import GoLink from '@app/components/go/GoLink';
import { HamburgerIcon } from '@app/components/icons/CommonIcons';
import DesktopLoggedInSection from '@app/components/layout/desktop/DesktopLoggedInSection';
import DesktopMenu from '@app/components/layout/desktop/DesktopMenu';
import { HeaderVariant } from '@app/components/layout/HeaderContainer';
import MubiNavLogo from '@app/components/layout/MubiNavLogo';
import NavSearchForm from '@app/components/layout/nav-search/NavSearchForm';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';

type DesktopNavProps = {
  logoType?: 'mubi' | 'go';
  variant?: HeaderVariant;
  color?: ColorHex;
};

const DesktopNav = ({
  color,
  logoType = 'mubi',
  variant = 'default',
}: DesktopNavProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const onboardingTrigger = useOnboardingTrigger();
  const [desktopMenuShowing, setDesktopMenuShowing] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { userIsInitialisedOnClient, user, isAuthenticated } = useAppSelector(
    state => ({
      userIsInitialisedOnClient: state.user.userIsInitialisedOnClient,
      user: state.user.user,
      isAuthenticated: state.user.isAuthenticated,
    }),
    shallowEqual,
  );

  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const { isPremiumPlanInCountry } = useGetGoAvailabilityForCountry();

  const openDesktopMenu = () => {
    trackSnowplowClickEvent({ data: { clickType: 'menu_hamburger' } });
    setDesktopMenuShowing(true);
  };

  const simpleVersion = variant === 'simplified' || variant === 'logo-only';

  const getAuthSection = (variant: HeaderVariant) => {
    if (!userIsInitialisedOnClient) {
      return null;
    }
    if (isAuthenticated) {
      if (simpleVersion) {
        return null;
      }
      return (
        <ProfileContainer>
          <DesktopLoggedInSection user={user} />
        </ProfileContainer>
      );
    } else if (variant !== 'logo-only') {
      return (
        <Login>
          <LoginLink
            color={color}
            onClick={() => {
              trackSnowplowClickEvent({ data: { clickType: 'tab_login' } });
              onboardingTrigger('loginNew');
            }}
            variant={variant}
          >
            {t('common:common.header_nav.login')}
          </LoginLink>
        </Login>
      );
    }
  };
  const onMenuViewChange = (isVisible: boolean) => {
    if (!isVisible && desktopMenuShowing) {
      setDesktopMenuShowing(false);
    }
  };

  const closeDesktopMenu = e => {
    if (desktopMenuShowing) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setDesktopMenuShowing(false);
    }
  };

  const authSection = getAuthSection(variant);

  return (
    <Container variant={variant}>
      <PageSection>
        <ContainerInner>
          <Logo
            onClick={() =>
              trackSnowplowClickEvent({ data: { clickType: 'tab_mubi_logo' } })
            }
          >
            <LogoIconContainer>
              <MubiNavLogo
                logoType={logoType}
                isAuthenticated={isAuthenticated}
                variant={variant}
                color={color}
              />
            </LogoIconContainer>
          </Logo>
          {!simpleVersion && (
            <NavSearchFormContainer>
              <NavSearchForm
                version="desktop"
                isInputFocused={isInputFocused}
                setContainerInputFocus={setIsInputFocused}
                variant={variant}
              />
              <RightOfSearchInput
                onClick={() => {
                  setIsInputFocused(true);
                }}
              />
            </NavSearchFormContainer>
          )}
          <RightNav fadeIn={userIsInitialisedOnClient}>
            {userIsInitialisedOnClient && (
              <>
                {!simpleVersion && (
                  <HeaderLink
                    href="/showing"
                    onClick={() =>
                      trackSnowplowClickEvent({
                        data: { clickType: 'tab_showing' },
                      })
                    }
                    selected={isLayoutNavItemSelected(
                      router.pathname,
                      'showing',
                    )}
                    variant={variant}
                  >
                    {router.pathname === '/showing' ? (
                      <h1>{t('common:common.header_nav.now_showing')}</h1>
                    ) : (
                      t('common:common.header_nav.now_showing')
                    )}
                  </HeaderLink>
                )}

                {isAuthenticated && !simpleVersion && (
                  <HeaderLink
                    href="/watchlist"
                    onClick={() =>
                      trackSnowplowClickEvent({
                        data: { clickType: 'tab_watchlist' },
                      })
                    }
                    selected={isLayoutNavItemSelected(
                      router.pathname,
                      'watchlist',
                    )}
                    variant={variant}
                  >
                    {t('common:common.subheader_nav.watchlist')}
                  </HeaderLink>
                )}

                {!simpleVersion && (
                  <HeaderLink
                    href="/notebook"
                    onClick={() =>
                      trackSnowplowClickEvent({
                        data: { clickType: 'tab_notebook' },
                      })
                    }
                    selected={isLayoutNavItemSelected(
                      router.pathname,
                      'notebook',
                    )}
                    variant={variant}
                  >
                    {t('common:common.header_nav.notebook')}
                  </HeaderLink>
                )}

                {isPremiumPlanInCountry && !simpleVersion && (
                  <GoLink>
                    <GoHeaderLink
                      onClick={() =>
                        trackSnowplowClickEvent({
                          data: { clickType: 'tab_mubi_go' },
                        })
                      }
                      selected={isLayoutNavItemSelected(
                        router.pathname,
                        'mubigo',
                      )}
                      variant={variant}
                    >
                      MUBI GO
                    </GoHeaderLink>
                  </GoLink>
                )}
                {authSection}
                {variant !== 'logo-only' ? (
                  <DesktopMenuSection>
                    <HamburgerButton onClick={openDesktopMenu}>
                      <HamburgerIcon
                        color={getHeaderColor({
                          variant,
                          defaultColor: themeColors.darkGray,
                          fallbackColor: themeColors.white,
                          overrideColor: color,
                        })}
                      />
                    </HamburgerButton>
                    <InView onChange={onMenuViewChange}>
                      {({ ref, inView }) => (
                        <ClickOutside
                          onClickOutside={closeDesktopMenu}
                          active={desktopMenuShowing && inView}
                          onPressEscape={closeDesktopMenu}
                        >
                          <DesktopMenu
                            isShowing={desktopMenuShowing}
                            closeDesktopMenu={closeDesktopMenu}
                            showMainLinks={variant === 'simplified'}
                            menuRef={ref}
                          />
                        </ClickOutside>
                      )}
                    </InView>
                  </DesktopMenuSection>
                ) : null}
              </>
            )}
          </RightNav>
        </ContainerInner>
      </PageSection>
    </Container>
  );
};

const Container = styled.nav<{ variant: HeaderVariant }>`
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: ${props =>
    props.variant === 'default' ? `${props.theme.color.white}` : 'none'};
  border-bottom: ${props =>
    props.variant === 'default'
      ? `1px solid ${props.theme.color.midBackground}`
      : 'none'};
`;

const ContainerInner = styled.div`
  height: 50px;
  display: flex;
`;

const Logo = styled.div`
  padding-right: 21px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LogoIconContainer = styled.div`
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const NavSearchFormContainer = styled.div`
  flex-grow: 1.3;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  margin-right: 21px;

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    flex-grow: 2;
  }
`;

const RightOfSearchInput = styled.div`
  height: 100%;
  width: 100%;
`;

const Login = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 12px;
`;

const LoginLink = styled.div<{ variant: HeaderVariant; color: string }>`
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  margin: 0 18px 0 21px;
  font-family: ${props => props.theme.font.title};
  font-weight: 500;
  color: ${props =>
    props.color
      ? props.color
      : props.variant === 'default'
      ? props.theme.color.darkGray
      : props.variant === 'transparent'
      ? props.theme.color.rgbaWhite60Opacity
      : props.theme.color.white};
  text-transform: uppercase;

  &:hover {
    color: ${props =>
      props.color
        ? props.color
        : props.variant === 'default'
        ? props.theme.color.darkText
        : props.theme.color.white};
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    margin: 0 21px;
  }
`;

const RightNav = styled.div<{ fadeIn: boolean }>`
  transition: opacity 400ms;
  opacity: ${({ fadeIn }) => (fadeIn ? '1' : '0')};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0%;
  white-space: nowrap;
`;

const LinkStyles = props => css`
  margin-right: 18px;
  font-size: 14px;
  line-height: 14px;
  font-family: ${props.theme.font.title};
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 12px;
  @media (min-width: ${props.theme.mqNew.wide}) {
    margin-right: 21px;
  }
  ${props.variant === 'default'
    ? css`
        color: ${props.selected
          ? props.theme.color.darkText
          : props.theme.color.darkGray};
        &:visited {
          color: ${props.selected
            ? props.theme.color.darkText
            : props.theme.color.darkGray};
        }
        &:hover {
          color: ${props.theme.color.darkText};
          text-decoration: none;
        }
      `
    : css`
        color: ${props.selected
          ? props.theme.color.white
          : props.theme.color.rgbaWhite60Opacity};
        &:visited {
          color: ${props.selected
            ? props.theme.color.white
            : props.theme.color.rgbaWhite60Opacity};
        }
        &:hover {
          color: ${props.theme.color.white};
          text-decoration: none;
        }
      `};
`;

const HeaderLink = styled(Link)<{
  selected: boolean;
  variant: HeaderVariant;
}>`
  ${LinkStyles}
`;

const DesktopMenuSection = styled.div`
  position: relative;
  display: block;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

const HamburgerButton = styled.div`
  cursor: pointer;
  position: relative;
  // Clickable area
  &:after {
    content: '';
    padding: 15px;
    position: absolute;
    top: -7.5px;
    left: -7.5px;
  }
`;

const GoHeaderLink = styled.div<{
  selected: boolean;
  variant: HeaderVariant;
}>`
  ${LinkStyles}
`;

const ProfileContainer = styled.div`
  padding-top: 8px;
  height: 100%;
`;

export default DesktopNav;
