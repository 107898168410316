import HttpService from '@app/api/http-service';
import { Film } from '@app/api/resources/Film';
import { Image } from '@app/api/resources/FilmHighlight';
import { ObjectOfStrings } from '@app/api/utility-types';

export type MubiGoConfig = {
  mubi_go_enabled_country: boolean;
  cinema?: MubiGoCinema;
  date_available: string;
  week_start_day: string;
  show_screening_attributes: boolean;
};

type MubiGoCinema = {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  time_zone: string;
  address: string;
  url: string;
  city: string;
};

export type MubiGoProgramming = {
  id: number;
  available_at: string;
  expires_at: string;
  film_of_the_week: boolean;
  editorial: string;
  editorial_html: string;
  next_weeks_film: boolean;
  film: Film;
  available_on: string;
  leaves_on: string;
  short_desktop_image?: Image;
  short_mobile_image?: Image;
  short_wide_image?: Image;
};

export const getMubiGoProgrammings = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: MubiGoProgramming[];
}> => HttpService(httpContext).get('/mubi_go/programmings');

export const getMubiGoConfig = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: MubiGoConfig;
}> => HttpService(httpContext).get('/app_config/mubi_go');
