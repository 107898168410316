import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { SearchResultTitle } from '@app/components/layout/nav-search/NavSearchResults';

const NavSearchNoFilmResult = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <SearchResultTitle>
        {t('common:common.now_showing_switch_selector.now_showing')}
      </SearchResultTitle>
      <NoResultFilm>{t('common:common.now_showing_no_results')}</NoResultFilm>
    </Container>
  );
};

export default NavSearchNoFilmResult;

const Container = styled.div`
  background-color: ${props => props.theme.color.white};
`;

const NoResultFilm = styled.div`
  padding: 0 15px;
  font-family: ${props => props.theme.font.title};
  font-weight: 500;
  color: ${props => props.theme.color.darkText};
  font-size: 14px;
  line-height: 16px;
`;
