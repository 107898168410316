import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Notification } from '@app/api/resources/Notifications';
import { Translate } from '@app/api/utility-types';

import NotificationElement from '@app/components/layout/notifications/NotificationElement';

const NotificationMenu = ({
  notifications = [],
}: {
  notifications?: Notification[];
}) => {
  const { t } = useTranslation('common');
  const notificationItems = getNotificationItems(notifications, t);
  return (
    <Container>
      <Triangle />
      <TopSection>{t('common:common.notifications.header')}</TopSection>
      <Notifications numNotifications={notifications?.length}>
        {notificationItems}
      </Notifications>
    </Container>
  );
};

const getNotificationItems = (notifications: Notification[], t: Translate) => {
  if (notifications?.length > 0) {
    return notifications.map(notification => (
      <NotificationElement
        key={`${notification.created_at}${notification.body_html}`}
        notification={notification}
      />
    ));
  }
  return (
    <NoNotifications>{t('common:common.notifications.none')}</NoNotifications>
  );
};

const Container = styled.div`
  width: 300px;
  background-color: white;
  position: relative;
  box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.13);
  z-index: 2;
`;

const Triangle = styled.i`
  transform: rotate(45deg);
  width: 13px;
  height: 13px;
  background-color: ${props => props.theme.color.white};
  position: absolute;
  top: -5px;
  right: 16px;
  z-index: 1;
  box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.13);
`;

const TopSection = styled.div`
  padding: 13px 15px;
  background-color: ${props => props.theme.color.white};
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid ${props => props.theme.color.midBackground};
  z-index: 1;
  position: relative;
`;

const Notifications = styled.div<{ numNotifications: number }>`
  max-height: 505px;
  height: ${props => (props.numNotifications === 0 ? '40px' : '40vh')};
  overflow-y: auto;
  @media (min-height: 450px) {
    height: ${props => (props.numNotifications === 0 ? '40px' : '65vh')};
  }
`;

const NoNotifications = styled.div`
  padding: 13px 15px;
  font-family: ${props => props.theme.font.body};
  color: ${props => props.theme.color.lightText};
  font-size: 12px;
`;

export default NotificationMenu;
