import { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getMubiShopUrl } from '@app/services/promo-utils';

import { setShowFindUsersModal } from '@app/actions/AppStateActions';

import useBrowseFilmsUrl from '@app/hooks/helpers/useBrowseFilmsUrl';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import MenuItem from '@app/components/layout/menu-links/MenuItem';
import {
  MenuHardLink,
  MenuLink,
} from '@app/components/layout/menu-links/MenuLink';
import MenuLinkButton from '@app/components/layout/menu-links/MenuLinkButton';
import OnboardingSignupCtaButtonContainer from '@app/components/onboarding/OnboardingSignupCtaButtonContainer';

type MenuLinksLoggedInProps = {
  closeMenu: () => void;
  userIsActiveSubscriber: boolean;
  geoLocation: string;
  showHelpScoutPopUp: (e: SyntheticEvent) => void;
  showMainLinksOnDesktop: boolean;
};

const MenuLinksLoggedIn = ({
  closeMenu,
  userIsActiveSubscriber,
  showHelpScoutPopUp,
  geoLocation = null,
  showMainLinksOnDesktop = false,
}: MenuLinksLoggedInProps) => {
  const { t } = useTranslation('common');
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const dispatch = useDispatch();
  const browseFilmsUrl = useBrowseFilmsUrl();

  return (
    <>
      {!userIsActiveSubscriber ||
        (showMainLinksOnDesktop && (
          <MenuItem>
            <OnboardingSignupCtaButtonContainer>
              {({ showModal, context, ctaButtonText }) => (
                <MenuHardLink
                  role="button"
                  onClick={() => {
                    trackSnowplowClickEvent({
                      data: { clickType: 'menu_7_days' },
                    });
                    closeMenu();
                    showModal(context);
                  }}
                >
                  {ctaButtonText}
                </MenuHardLink>
              )}
            </OnboardingSignupCtaButtonContainer>
          </MenuItem>
        ))}
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_browse' } })
        }
      >
        <MenuLink href={browseFilmsUrl}>
          {t('common:common.subheader_nav.browse')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({
            data: { clickType: 'menu_viewing_history' },
          })
        }
      >
        <MenuLink href="/viewing-history">
          {t('common:common.subheader_nav.view_log')}
        </MenuLink>
      </MenuItem>

      <ItemDivider />

      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_find_users' } })
        }
      >
        <MenuLinkButton
          onClick={() => {
            closeMenu();
            dispatch(setShowFindUsersModal(true));
          }}
        >
          {t('common:common.subheader_nav.find_users')}
        </MenuLinkButton>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({
            data: { clickType: 'menu_invite_friends' },
          })
        }
      >
        <MenuLink href="/referrals">
          {t('common:common.subheader_nav.earn_1_month_free')}
        </MenuLink>
      </MenuItem>
      {(geoLocation === 'GB' || geoLocation === 'US') && (
        <MenuItem
          onClick={() =>
            trackSnowplowClickEvent({ data: { clickType: 'menu_shop' } })
          }
        >
          <MenuHardLink href={getMubiShopUrl(geoLocation)}>SHOP</MenuHardLink>
        </MenuItem>
      )}
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_gift_mubi' } })
        }
      >
        <MenuLink href="/gifts">
          {t('common:common.subheader_nav.gift')}
        </MenuLink>
      </MenuItem>

      <ItemDivider />

      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_about' } })
        }
      >
        <MenuLink href="/about">
          {t('common:common.subheader_nav.about')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_students' } })
        }
      >
        <MenuLink href="/student">
          {t('common:common.subheader_nav.students')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_contribute' } })
        }
      >
        <MenuHardLink href="/contribute">
          {t('common:common.subheader_nav.contribute')}
        </MenuHardLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_jobs' } })
        }
      >
        <MenuHardLink href={process.env.JOBS_SITE_URL}>
          {t('common:common.subheader_nav.jobs')}
        </MenuHardLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_terms' } })
        }
      >
        <MenuLink href="/terms_of_service">
          {t('common:common.subheader_nav.terms')}
        </MenuLink>
      </MenuItem>

      <ItemDivider />

      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_settings' } })
        }
      >
        <MenuLink href="/account">
          {t('common:common.subheader_nav.settings')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_help' } })
        }
      >
        <MenuLinkButton onClick={showHelpScoutPopUp}>
          {t('common:common.subheader_nav.help')}
        </MenuLinkButton>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_logout' } })
        }
      >
        <MenuLink href="/logout">
          {t('common:common.subheader_nav.logout')}
        </MenuLink>
      </MenuItem>
    </>
  );
};

const ItemDivider = styled.li`
  border-top: 1px solid ${props => props.theme.color.midBackground};
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
`;

export default MenuLinksLoggedIn;
