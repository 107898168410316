import { cloneElement, useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import {
  getNotificationsForUser,
  Notification,
} from '@app/api/resources/Notifications';

import { setNotifications } from '@app/actions/NotificationActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const NotificationsContainer = ({
  children,
  onClick = () => {},
}: {
  children: ({
    notifications,
  }: {
    notifications: Notification[];
  }) => JSX.Element;
  onClick?: () => void;
}) => {
  const dispatch = useDispatch();
  const { httpContext, notifications } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      notifications: state.notification.notifications,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (notifications === null) {
      initialiseNotifications();
    }
  }, []);

  const initialiseNotifications = async () => {
    try {
      const userNotificationsResponse = await getNotificationsForUser(
        httpContext,
      );
      const userNotifications = userNotificationsResponse.data;
      dispatch(setNotifications(userNotifications));
    } catch (error) {
      dispatch(setNotifications(null));
    }
  };

  return cloneElement(children({ notifications }), { onClick });
};

export default NotificationsContainer;
