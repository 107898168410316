import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { showHelpScoutPopUp } from '@app/services/helpscout';

import { useGetGoAvailabilityForCountry } from '@app/hooks/helpers/useGetGoAvailabilityForCountry';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import GoLink from '@app/components/go/GoLink';
import MenuItem from '@app/components/layout/menu-links/MenuItem';
import {
  MenuHardLink,
  MenuLink,
} from '@app/components/layout/menu-links/MenuLink';
import MenuLinksLoggedIn from '@app/components/layout/menu-links/MenuLinksLoggedIn';
import MenuLinksLoggedOut from '@app/components/layout/menu-links/MenuLinksLoggedOut';
import OnboardingSignupCtaButtonContainer from '@app/components/onboarding/OnboardingSignupCtaButtonContainer';

type MenuLinksProps = {
  userIsAuthenticated: boolean;
  userIsActiveSubscriber: boolean;
  userIsAdmin: boolean;
  userId?: number;
  geoLocation: string;
  closeMenu: () => void;
  currentLanguage?: string;
  showMainLinksOnDesktop?: boolean;
};

const MenuLinks = ({
  userIsAuthenticated,
  userIsActiveSubscriber,
  userIsAdmin,
  userId = null,
  closeMenu,
  currentLanguage = '',
  geoLocation = '',
  showMainLinksOnDesktop = false,
}: MenuLinksProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const { isPremiumPlanInCountry } = useGetGoAvailabilityForCountry();

  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const showMenuHelpScoutPopUp = e => {
    showHelpScoutPopUp(e, currentLanguage);
    closeMenu();
  };

  return (
    <Container>
      {!userIsActiveSubscriber && (
        <MenuItem>
          <OnboardingSignupCtaButtonContainer>
            {({ showModal, context, ctaButtonText }) => (
              <MenuHardLink
                role="button"
                onClick={() => {
                  trackSnowplowClickEvent({
                    data: { clickType: 'menu_7_days' },
                  });
                  closeMenu();
                  showModal(context);
                }}
              >
                {ctaButtonText}
              </MenuHardLink>
            )}
          </OnboardingSignupCtaButtonContainer>
        </MenuItem>
      )}
      <MainLinksItem showOnDesktop={showMainLinksOnDesktop}>
        <MainLinksContainer>
          <MenuItem
            onClick={() =>
              trackSnowplowClickEvent({
                data: { clickType: 'menu_now_showing' },
              })
            }
          >
            <MenuLink href="/showing">
              {router.pathname === '/showing' ? (
                <h1>{t('common:common.header_nav.now_showing')}</h1>
              ) : (
                t('common:common.header_nav.now_showing')
              )}
            </MenuLink>
          </MenuItem>
          {userIsAuthenticated && (
            <MenuItem
              onClick={() =>
                trackSnowplowClickEvent({
                  data: { clickType: 'navbar_watchlist' },
                })
              }
            >
              <MenuLink href="/watchlist">
                {t('common:common.subheader_nav.watchlist')}
              </MenuLink>
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              trackSnowplowClickEvent({ data: { clickType: 'menu_notebook' } })
            }
          >
            <MenuLink href="/notebook">
              {t('common:common.header_nav.notebook')}
            </MenuLink>
          </MenuItem>
          {isPremiumPlanInCountry && (
            <MenuItem
              onClick={() =>
                trackSnowplowClickEvent({ data: { clickType: 'menu_mubi_go' } })
              }
            >
              <GoLink>
                <MenuHardLink as="span">MUBI GO</MenuHardLink>
              </GoLink>
            </MenuItem>
          )}
          {userIsAuthenticated && (
            <MenuItem
              onClick={() =>
                trackSnowplowClickEvent({ data: { clickType: 'menu_profile' } })
              }
            >
              <MenuLink href={`/users/${userId}`}>
                {t('common:common.header_nav.profile')}
              </MenuLink>
            </MenuItem>
          )}
          <ItemDivider />
        </MainLinksContainer>
      </MainLinksItem>
      {userIsAdmin && (
        <MenuItem>
          <MenuHardLink href="/admin">Admin</MenuHardLink>
        </MenuItem>
      )}
      {userIsAuthenticated ? (
        <MenuLinksLoggedIn
          closeMenu={closeMenu}
          userIsActiveSubscriber={userIsActiveSubscriber}
          geoLocation={geoLocation}
          showHelpScoutPopUp={showMenuHelpScoutPopUp}
          showMainLinksOnDesktop={showMainLinksOnDesktop}
        />
      ) : (
        <MenuLinksLoggedOut
          geoLocation={geoLocation}
          showHelpScoutPopUp={showMenuHelpScoutPopUp}
        />
      )}
    </Container>
  );
};

const Container = styled.ul`
  padding: 16px 0 150px;
  width: 100%;
  z-index: 3;
  position: relative;
  background-color: ${props => props.theme.color.white};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 11px 0;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 100%;
  }
`;

const MainLinksItem = styled.li<{ showOnDesktop: boolean }>`
  padding: 0;
  display: block;
  ${props =>
    props.showOnDesktop
      ? ''
      : `@media (min-width: ${props.theme.mqNew.desktop}) { display: none; }`}
`;

const MainLinksContainer = styled.ul`
  padding: 0;
  width: 100%;
  height: 100%;
`;

const ItemDivider = styled.li`
  border-top: 1px solid ${props => props.theme.color.midBackground};
  margin-top: 10px;
  padding-bottom: 10px;
  display: block;
`;

export default MenuLinks;
