import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getHeaderColor } from '@app/services/utils';

import { color as themeColors, ColorHex } from '@app/themes/mubi-theme';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import { HamburgerIcon } from '@app/components/icons/CommonIcons';
import { HeaderVariant } from '@app/components/layout/HeaderContainer';
import MobileMenu from '@app/components/layout/mobile/MobileMenu';
import MubiNavLogo from '@app/components/layout/MubiNavLogo';
import NotificationDot from '@app/components/layout/notifications/NotificationDot';
import NotificationsContainer from '@app/components/layout/notifications/NotificationsContainer';
import PageSection from '@app/components/layout/PageSection';

const MobileNav = ({
  variant = 'default',
  logoType = 'mubi',
  color,
}: {
  color?: ColorHex;
  logoType?: 'mubi' | 'go';
  variant?: HeaderVariant;
}) => {
  const { t } = useTranslation('common');
  const [mobileMenuShowing, setMobileMenuShowing] = useState(false);
  const onboardingTrigger = useOnboardingTrigger();
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const { userIsInitialisedOnClient, isAuthenticated } = useAppSelector(
    state => ({
      userIsInitialisedOnClient: state.user.userIsInitialisedOnClient,
      isAuthenticated: state.user.isAuthenticated,
    }),
    shallowEqual,
  );

  const toggleMobileMenu = () => {
    if (!mobileMenuShowing) {
      trackSnowplowClickEvent({ data: { clickType: 'menu_hamburger' } });
    }
    setMobileMenuShowing(!mobileMenuShowing);
  };

  const closeMobileMenu = () => {
    setMobileMenuShowing(false);
  };

  const getRightColumn = () => {
    if (userIsInitialisedOnClient) {
      if (isAuthenticated) {
        if (variant === 'simplified' || variant === 'logo-only') {
          return null;
        }
        return (
          <Notifications>
            <NotificationsContainer
              onClick={() =>
                trackSnowplowClickEvent({
                  data: { clickType: 'tab_notifications' },
                })
              }
            >
              {({ notifications }) => (
                <NotificationDot notifications={notifications} />
              )}
            </NotificationsContainer>
          </Notifications>
        );
      }
      return (
        <>
          {variant !== 'logo-only' ? (
            <Login
              onClick={() =>
                trackSnowplowClickEvent({ data: { clickType: 'tab_login' } })
              }
            >
              <LoginLink
                onClick={() => onboardingTrigger('loginNew')}
                variant={variant}
              >
                {t('common:common.header_nav.login')}
              </LoginLink>
            </Login>
          ) : null}
        </>
      );
    }
    return null;
  };

  const rightColumn = getRightColumn();

  return (
    <Container variant={variant}>
      <MobileMenu
        isShowing={mobileMenuShowing}
        doClose={closeMobileMenu}
        showSearch={variant !== 'simplified' && variant !== 'logo-only'}
      />
      <PageSection fullHeight fromBreakpoint="tablet">
        <ContainerInner>
          {variant !== 'logo-only' ? (
            <Hamburger fadeIn={userIsInitialisedOnClient}>
              {userIsInitialisedOnClient && (
                <HamburgerButton
                  onClick={toggleMobileMenu}
                  data-testid="hamburger"
                  role="button"
                  aria-label="Open navigation sidebar"
                >
                  <HamburgerIcon
                    color={getHeaderColor({
                      variant,
                      defaultColor: themeColors.darkGray,
                      fallbackColor: themeColors.white,
                      overrideColor: color,
                    })}
                  />
                </HamburgerButton>
              )}
            </Hamburger>
          ) : null}
          <Logo
            onClick={() =>
              trackSnowplowClickEvent({ data: { clickType: 'tab_mubi_logo' } })
            }
          >
            <LogoIconContainer>
              <MubiNavLogo
                logoType={logoType}
                isAuthenticated={isAuthenticated}
                variant={variant}
                color={color}
              />
            </LogoIconContainer>
          </Logo>
          <RightContainer fadeIn={userIsInitialisedOnClient}>
            {rightColumn}
          </RightContainer>
        </ContainerInner>
      </PageSection>
    </Container>
  );
};

const Container = styled.nav<{ variant: HeaderVariant }>`
  position: relative;
  height: 60px;
  width: 100%;
  background-color: ${props =>
    props.variant === 'default' ? `${props.theme.color.white}` : 'none'};
  border-bottom: ${props =>
    props.variant === 'default'
      ? `1px solid ${props.theme.color.midBackground}`
      : 'none'};
  z-index: 10;
`;

const ContainerInner = styled.nav`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const Hamburger = styled.div<{ fadeIn: boolean }>`
  transition: opacity 400ms;
  opacity: ${({ fadeIn }) => (fadeIn ? '1' : '0')};

  position: absolute;
  left: 0;
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const HamburgerButton = styled.div`
  cursor: pointer;
  position: relative;
  // Clickable area
  &:after {
    content: '';
    padding: 15px;
    position: absolute;
    top: -7.5px;
    left: -7.5px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LogoIconContainer = styled.div`
  display: flex;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Login = styled.div`
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const LoginLink = styled(UnstyledButton)`
  font-size: 14px;
  line-height: 14px;
  font-family: ${props => props.theme.font.title};
  font-weight: 500;

  color: ${props =>
    props.variant === 'default'
      ? props.theme.color.darkGray
      : props.theme.color.white};
  text-transform: uppercase;

  &:hover {
    color: ${props =>
      props.variant === 'default'
        ? props.theme.color.darkText
        : props.theme.color.white};

    text-decoration: underline;
  }
`;

const Notifications = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const RightContainer = styled.div<{ fadeIn: boolean }>`
  transition: opacity 400ms;
  opacity: ${({ fadeIn }) => (fadeIn ? '1' : '0')};

  position: absolute;
  right: 0;
  height: 100%;
`;

export default MobileNav;
