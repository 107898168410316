import { RefObject, SyntheticEvent } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import MenuLinksContainer from '@app/components/layout/MenuLinksContainer';

type DesktopMenuProps = {
  isShowing?: boolean;
  closeDesktopMenu: (e: SyntheticEvent) => void;
  showMainLinks?: boolean;
  menuRef: RefObject<any> | ((node?: Element | null) => void);
};

const DesktopMenu = ({
  isShowing = true,
  closeDesktopMenu,
  showMainLinks = false,
  menuRef,
}: DesktopMenuProps) => {
  return (
    <CSSTransition in={isShowing} classNames="appear" timeout={200}>
      <Container ref={menuRef}>
        <Arrow />
        <MenuLinksContainer
          closeMenu={closeDesktopMenu}
          showMainLinksOnDesktop={showMainLinks}
        />
      </Container>
    </CSSTransition>
  );
};

const Container = styled.div`
  position: absolute;
  top: 63px;
  right: -10px;
  left: auto;
  min-width: 173px;
  background-color: white;
  box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.13);
  z-index: 3;
  display: none;

  &.appear-enter {
    display: block;
    transform: translateY(-15px);
    opacity: 0;
  }

  &.appear-enter.appear-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.2s, opacity 0.2s;
  }

  &.appear-enter-done {
    display: block;
  }

  &.appear-exit {
    display: block;
    transform: translateY(0);
    opacity: 1;
  }

  &.appear-exit.appear-exit-active {
    transform: translateY(-15px);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
  }
`;

const Arrow = styled.div`
  transform: rotate(45deg);
  width: 13px;
  height: 13px;
  background: ${props => props.theme.color.white};
  position: absolute;
  top: -5px;
  right: 14px;
  z-index: 1;
  box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.13);
  display: block;
`;

export default DesktopMenu;
