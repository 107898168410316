import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';

import { UserStateBannerType } from '@app/api/resources/User';

import { sanitize } from '@app/services/utils';

import { clearUserStateBanners } from '@app/actions/BannerActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import UserStateBanner from '@app/components/banners/UserStateBanner';

const UserStateBannersContainer = () => {
  const userStateBanners = useAppSelector(
    state => state.banners?.userStateBanners || [],
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    destroyCookie(null, 'flash_store', { path: '/' });
  }, []);

  const doDismiss = () => {
    if (Array.isArray(userStateBanners) && userStateBanners.length > 0) {
      dispatch(clearUserStateBanners());
    }
  };

  const bannersToShow: ReactNode[] = [];

  if (userStateBanners.length > 0) {
    bannersToShow.push(
      userStateBanners.map(banner => (
        <div key={banner.message}>
          <UserStateBanner
            messageString={banner.message}
            messageType={banner.type}
            onDismiss={doDismiss}
          />
        </div>
      )),
    );
  }
  const message = sanitize(
    (query?.notice as string) ?? (query?.alert as string),
  );
  if (message && typeof message === 'string' && message.length > 1) {
    bannersToShow.push(
      <div key={message}>
        <UserStateBanner
          messageString={message}
          messageType={Object.keys(query)[0] as UserStateBannerType}
        />
      </div>,
    );
  }
  return <>{bannersToShow}</>;
};

export default UserStateBannersContainer;
