import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import { CloseIcon } from '@app/components/icons/CommonIcons';
import MenuLinksContainer from '@app/components/layout/MenuLinksContainer';
import NavSearchForm from '@app/components/layout/nav-search/NavSearchForm';

type MobileMenuProps = {
  isShowing?: boolean;
  doClose: () => void;
  showSearch?: boolean;
};

const MobileMenu = ({
  isShowing = false,
  doClose,
  showSearch = true,
}: MobileMenuProps) => {
  const [showSlideOutDrawer, setShowSlideOutDrawer] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const startDrawerOpen = () => {
    setShowSlideOutDrawer(true);
  };

  const startClose = () => {
    try {
      setShowSlideOutDrawer(false);
    } catch (error) {
      Sentry.captureException(new Error('Error in startClose in MobileMenu'));
    }
  };

  // time for onboarding modal to open
  const startCloseDelayed = () => {
    setTimeout(startClose, 500);
  };

  return (
    <CSSTransition
      in={isShowing}
      timeout={{
        enter: 0,
        exit: 200,
      }}
      classNames="show"
      onEntered={startDrawerOpen}
    >
      <Container>
        <CSSTransition
          in={showSlideOutDrawer}
          classNames="fadein"
          timeout={200}
        >
          <OpacityOverlay onClick={startClose} />
        </CSSTransition>
        <CSSTransition
          in={showSlideOutDrawer}
          classNames="slidein"
          timeout={200}
          onExited={doClose}
        >
          <SlideOutDrawer>
            <SlideOutDrawerInner>
              {showSearch && (
                <NavSearchForm
                  variant="default"
                  isInputFocused={isInputFocused}
                  setContainerInputFocus={setIsInputFocused}
                />
              )}
              <MenuLinksContainer closeMenu={startCloseDelayed} />
              <CloseIconContainer onClick={startClose}>
                <CloseIcon color="white" width="20px" />
              </CloseIconContainer>
            </SlideOutDrawerInner>
          </SlideOutDrawer>
        </CSSTransition>
      </Container>
    </CSSTransition>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 12;
  display: none;

  &.show-enter-done {
    display: block;
  }
`;

const OpacityOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1005;
  opacity: 0;

  &.fadein-enter-active {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &.fadein-enter-done {
    opacity: 1;
  }
`;

const SlideOutDrawer = styled.div`
  height: 100vh;
  width: calc(100% - 60px);
  max-width: 350px;
  background-color: white;
  z-index: 1006;
  position: relative;
  transform: translateX(-100%);

  &.slidein-enter-active {
    transform: translateX(0);
    transition: transform 0.2s;
  }

  &.slidein-enter-done {
    transform: translateX(0);
  }

  &.slidein-exit {
    transform: translateX(0);
  }

  &.slidein-exit.slidein-exit-active {
    transform: translateX(-100%);
    transition: transform 0.2s;
  }
`;

const SlideOutDrawerInner = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: calc(100% + 20px);
`;

export default MobileMenu;
