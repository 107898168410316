import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import {
  getSnowplowPageType,
  trackSnowplowQuickSearchSuggestionsEvent,
} from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useSnowplowQuickSearchSuggestionsTracking = () => {
  const router = useRouter();
  const { httpContext, countryCode, vanityPathPageType } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const doTrackSnowplowQuickSearchSuggestionsEvent = async eventData => {
    const pageType = await getSnowplowPageType(router, vanityPathPageType);

    const { suggestions: searchSuggestions = [], ...restOfEventData } =
      eventData;
    const suggestions = searchSuggestions?.map(suggestion => {
      return {
        entity_type: 'film_suggestion',
        entity_id: suggestion?.id,
        entity_title: suggestion?.title,
      };
    });

    trackSnowplowQuickSearchSuggestionsEvent(
      {
        ...pageType,
        suggestions: JSON.stringify(suggestions),
        ...restOfEventData,
        country_code: countryCode,
        language: router.locale,
      },
      httpContext,
    );
  };

  return doTrackSnowplowQuickSearchSuggestionsEvent;
};

export default useSnowplowQuickSearchSuggestionsTracking;
