import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import styled from '@emotion/styled';

import { sanitize } from '@app/services/utils';

import useAppSelector from '@app/hooks/utils/useAppSelector';
import useShowFeedbackBanner from '@app/hooks/components/banners/useShowFeedbackBanner';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import FadeIn from '@app/components/FadeInAndOut';
import { CloseIconThin } from '@app/components/icons/CommonIcons';
import PageSection from '@app/components/layout/PageSection';

const MAX_PIXEL_WIDTH_PER_LETTER = 8.5;
const CLOSE_BUTTON_PADDING = 20;
const CLOSE_ICON_WIDTH = 12;
const LEFT_PADDING = 17;

const CLOSE_BUTTON_WIDTH_PLUS_LEFT_PADDING =
  CLOSE_BUTTON_PADDING * 2 + CLOSE_ICON_WIDTH + LEFT_PADDING;

const UserFeedbackBanner = ({
  autodismiss = true,
}: {
  autodismiss?: boolean;
}) => {
  const bannerFromStore = useAppSelector(
    state => state.banners?.userFeedbackBanner,
  );

  const messageFromStore = bannerFromStore?.message || '';

  const router = useRouter();
  const { query } = router;

  const queryParamMessage = sanitize(query?.feedback as string);

  const message = messageFromStore || queryParamMessage;

  // we need the width so we can have the banner as position:fixed relative to PageSection
  const approxBannerWidth =
    message.length * MAX_PIXEL_WIDTH_PER_LETTER +
    CLOSE_BUTTON_WIDTH_PLUS_LEFT_PADDING;

  const [showBanner, shouldShowElement, dismissBanner] = useShowFeedbackBanner(
    message,
    autodismiss,
    bannerFromStore?.id,
  );

  useEffect(() => {
    destroyCookie(null, 'flash_store', { path: '/' });
  }, []);

  const showRelativeBannerPosition = message.length < 80;

  return (
    <>
      {shouldShowElement ? (
        <Container>
          <PageSection relative fromBreakpoint="tablet" mobilePadding={false}>
            <RightAlignPositionMarker
              bannerWidth={
                showRelativeBannerPosition ? approxBannerWidth : null
              }
            >
              <FixedContainer>
                <FadeIn isShowing={showBanner} fadeSeconds={0.3}>
                  <Banner
                    bannerWidth={
                      showRelativeBannerPosition ? approxBannerWidth : null
                    }
                  >
                    <Message isError={bannerFromStore.isError}>
                      {message}
                    </Message>
                    <MubiButton actionType="blank" clickAction={dismissBanner}>
                      <CloseIconThin
                        color="black"
                        width={`${CLOSE_ICON_WIDTH}px`}
                      />
                    </MubiButton>
                  </Banner>
                </FadeIn>
              </FixedContainer>
            </RightAlignPositionMarker>
          </PageSection>
        </Container>
      ) : null}
    </>
  );
};

export default UserFeedbackBanner;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1001; // higher than overlay
`;

const RightAlignPositionMarker = styled.div<{ bannerWidth: number }>`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: absolute;
    right: 20px;
    width: ${({ bannerWidth }) => (bannerWidth ? `${bannerWidth}px` : '50vw')};
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  z-index: 11;
  width: 100%;
  top: 65px;
  padding: 0 18px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: initial;
    width: max-content;
  }
`;

const Banner = styled.div<{ bannerWidth: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(251, 251, 251);
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 4px;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 0 10px ${LEFT_PADDING}px;
  pointer-events: initial;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: ${({ bannerWidth }) => (bannerWidth ? `${bannerWidth}px` : '50vw')};
  }
`;

const Message = styled.div<{ isError: boolean }>`
  text-align: center;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    text-align: left;
  }
  width: 100%;
  color: ${props =>
    props.isError ? props.theme.color.alertRed : props.theme.color.black};
`;
