import styled from '@emotion/styled';

import { PlayIcon } from '@app/components/icons/CommonIcons';
import Link from '@app/components/Link';

type NavSearchPictureResultProps = {
  imageUrl: string;
  title: string;
  subtitle: string;
  resultUrl: string;
  showPlay?: boolean;
  numTitleLines?: number;
};

const NavSearchPictureResult = ({
  imageUrl,
  title,
  subtitle,
  resultUrl,
  showPlay = false,
  numTitleLines = 1,
}: NavSearchPictureResultProps) => (
  <ResultLink href={resultUrl}>
    <ResultImage imageUrl={imageUrl}>
      {showPlay && (
        <PlayIconContainer>
          <PlayIcon width="100%" showHoverState={false} />
        </PlayIconContainer>
      )}
    </ResultImage>
    <AboutResult>
      <ResultTitle numTitleLines={numTitleLines}>{title}</ResultTitle>
      <ResultSubtitle>{subtitle}</ResultSubtitle>
    </AboutResult>
  </ResultLink>
);

const ResultLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 3px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.color.midBackground};
    text-decoration: none;
  }
`;

const ResultImage = styled.div<{
  imageUrl: string;
}>`
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  background-image: url(${props => props.imageUrl});
  background-color: ${props => props.theme.color.midBackground};
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AboutResult = styled.div`
  padding-left: 15px;
  min-width: 0;
  overflow: hidden; /* required for long titles on ie11 */
`;

const ResultTitle = styled.div<{
  numTitleLines: number;
}>`
  font-family: ${props => props.theme.font.title};
  font-weight: 500;
  color: ${props => props.theme.color.darkText};

  max-height: ${props => props.numTitleLines * 16}px;
  line-height: 16px;

  display: -webkit-box;
  -webkit-line-clamp: ${props => props.numTitleLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ResultSubtitle = styled.div`
  color: ${props => props.theme.color.midGray};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PlayIconContainer = styled.div`
  width: 30px;
`;

export default NavSearchPictureResult;
