import HttpService from '@app/api/http-service';
import { ResponseWithError } from '@app/api/resources/Responses';
import { ObjectOfStrings } from '@app/api/utility-types';

export type NotebookCover = {
  id: number;
  excerpt?: string;
  image_url?: string;
  title: string;
  url: string;
  title_html?: string;
  excerpt_html?: string;
};

export const getNotebookCover = (
  httpContext: ObjectOfStrings,
  coverId: number = null,
): ResponseWithError<NotebookCover> => {
  if (coverId) {
    return HttpService(httpContext).get(`/notebook/cover/${coverId}`);
  }
  return HttpService(httpContext).get('/notebook/cover');
};
