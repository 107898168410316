import { ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type FadeInAndOutProps = {
  children: ReactNode;
  isShowing: boolean;
  fadeSeconds?: number;
  zIndex?: string;
  fadeStyle?: 'simple-fade' | 'up-down-fade';
};

const FadeInAndOut = ({
  isShowing,
  children,
  fadeSeconds = 0.5,
  zIndex = '1',
  fadeStyle = 'simple-fade',
}: FadeInAndOutProps) => {
  const [fadeInOrOut, setFadeInOrOut] = useState(false);

  useEffect(() => {
    setFadeInOrOut(isShowing);
  }, [isShowing]);

  return (
    <CSSTransition
      in={fadeInOrOut}
      appear={fadeInOrOut}
      classNames="appear"
      timeout={fadeSeconds * 1000}
    >
      <Container
        fadeSeconds={fadeSeconds}
        zIndex={zIndex}
        fadeStyle={fadeStyle}
      >
        {children}
      </Container>
    </CSSTransition>
  );
};

export default FadeInAndOut;

const simpleFadeAnimation = css`
  &.appear-appear,
  &.appear-enter,
  &.appear-enter-done,
  &.appear-exit {
    display: block;
  }
  &.appear-appear-active,
  &.appear-enter-active,
  &.appear-enter-done {
    opacity: 1;
  }
`;

const upDownFadeAnimation = css`
  &.appear-appear,
  &.appear-enter,
  &.appear-exit {
    transform: translateY(30px);
    display: block;
  }

  &.appear-enter-done {
    transform: translateY(0px);
    display: block;
  }

  &.appear-appear-active,
  &.appear-enter-active,
  &.appear-enter-done {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const Container = styled.div<{
  fadeSeconds: number;
  zIndex: string;
  fadeStyle: string;
}>`
  display: none;
  transition: ${props =>
    `opacity ${props.fadeSeconds}s, transform ${props.fadeSeconds}s`};
  position: relative;
  z-index: ${props => props.zIndex};
  opacity: 0;

  ${props => props.fadeStyle === 'simple-fade' && simpleFadeAnimation}

  ${props => props.fadeStyle === 'up-down-fade' && upDownFadeAnimation}
`;
