import { CastMemberSearchResult } from '@app/api/services/search';
import { stripDomainFromUrl } from '@app/services/routeHelpers';

import NavSearchPictureResult from '@app/components/layout/nav-search/NavSearchPictureResult';

const NavSearchCastMemberResult = ({
  castMemberResult,
}: {
  castMemberResult: CastMemberSearchResult;
}) => {
  const getImage = (castMember: CastMemberSearchResult) =>
    castMember?.image_urls?.square ?? '';

  return (
    <NavSearchPictureResult
      imageUrl={getImage(castMemberResult)}
      title={castMemberResult.name}
      subtitle={castMemberResult.primary_type}
      resultUrl={stripDomainFromUrl(castMemberResult.canonical_url)}
    />
  );
};

export default NavSearchCastMemberResult;
