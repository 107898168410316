import styled from '@emotion/styled';

import { ColorHex } from '@app/themes/mubi-theme';

import useAppSelector from '@app/hooks/utils/useAppSelector';
import usePrefetchTopNavData from '@app/hooks/utils/usePrefetchTopNavData';

import LocaleNoticeContainer from '@app/components/banners/LocaleNoticeContainer';
import UserFeedbackBanner from '@app/components/banners/UserFeedbackBanner';
import UserStateBannersContainer from '@app/components/banners/UserStateBannersContainer';
import DesktopNav from '@app/components/layout/desktop/DesktopNav';
import MobileNav from '@app/components/layout/mobile/MobileNav';

export const HEADER_HEIGHT_MOBILE = '60px';
export const HEADER_HEIGHT_DESKTOP = '50px';

export type HeaderVariant =
  | 'transparent'
  | 'simplified'
  | 'default'
  | 'logo-only';

type HeaderContainerProps = {
  variant?: HeaderVariant;
  color?: ColorHex;
  logoType?: 'mubi' | 'go';
};

const HeaderContainer = ({
  variant = 'default',
  logoType = 'mubi',
  color,
}: HeaderContainerProps) => {
  const hideTopNav = useAppSelector(state => state.appState.hideTopNav);

  usePrefetchTopNavData();

  if (hideTopNav) {
    return null;
  }

  return (
    <>
      <LocaleNoticeContainer />
      <UserStateBannersContainer />
      <MobileNavContainer>
        <MobileNav variant={variant} color={color} logoType={logoType} />
      </MobileNavContainer>
      <DesktopNavContainer>
        <DesktopNav variant={variant} color={color} logoType={logoType} />
      </DesktopNavContainer>
      <UserFeedbackBanner />
    </>
  );
};

const DesktopNavContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: block;
  }
`;
const MobileNavContainer = styled.div`
  display: block;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

export default HeaderContainer;
