import { useEffect } from 'react';

import { getCollectionMarqueeFilm } from '@app/api/resources/Film';
import { getMubiGoProgrammings } from '@app/api/resources/MubiGo';
import { getNotebookCover } from '@app/api/resources/notebook/NotebookCover';

import { initialiseTrendingFilms } from '@app/services/page-initialisation/splash';

import { useGetGoAvailabilityForCountry } from '@app/hooks/helpers/useGetGoAvailabilityForCountry';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const MS_TO_WAIT_BEFORE_PREFETCHING = 1500;

const usePrefetchTopNavData = () => {
  const httpContext = useAppSelector(state => state.appState.httpContext);
  const geoLocation = useAppSelector(state => state.user.geoLocation);
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);

  const { isPremiumPlanInCountry } = useGetGoAvailabilityForCountry();

  const prefetchForSplashPage = async () => {
    try {
      await initialiseTrendingFilms(httpContext, geoLocation);
    } catch (error) {
      // Ignore trending prefeching error
    }
  };

  const prefetchForMubiGoPage = async () => {
    try {
      await getMubiGoProgrammings(httpContext);
    } catch (error) {
      // Client-Country without Mubi GO will return an error
    }
  };

  const prefetchForNotebookPage = async () => {
    try {
      await getNotebookCover(httpContext);
    } catch (error) {
      // Ignore get notebook cover prefeching error
    }
  };

  const prefetchForShowingPage = async () => {
    try {
      await getCollectionMarqueeFilm(httpContext);
    } catch (error) {
      // Ignore marquee prefeching error
    }
  };

  useEffect(() => {
    if (process.env.MUBI_ENV !== 'development') {
      setTimeout(() => {
        if (!isAuthenticated) {
          prefetchForSplashPage();
        }
        prefetchForShowingPage();
        if (isPremiumPlanInCountry) {
          prefetchForMubiGoPage();
        }
        prefetchForNotebookPage();
      }, MS_TO_WAIT_BEFORE_PREFETCHING);
    }
  }, []);
};

export default usePrefetchTopNavData;
