import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import { FilmId, getFilm } from '@app/api/resources/Film';

import {
  getSnowplowPageType,
  trackSnowplowQuickSearchEvent,
} from '@app/services/snowplow';

import { ObjectOfAny } from '@app/types/utility-types';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useSnowplowQuickSearchTracking = () => {
  const router = useRouter();
  const { httpContext, countryCode, vanityPathPageType } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const getConsumableForFilm = async (filmId: FilmId) => {
    try {
      const { data: film } = await getFilm(httpContext, filmId);
      const { consumable } = film;
      return consumable;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const getRelatedState = async (relatedIds: ObjectOfAny) => {
    const relatedState: {
      is_watchable?: boolean;
      is_exclusive?: boolean;
    } = {};

    if (relatedIds?.film_id) {
      const consumablesForFilm = await getConsumableForFilm(
        relatedIds?.film_id,
      );

      relatedState.is_watchable =
        Array.isArray(consumablesForFilm?.offered) &&
        consumablesForFilm?.offered.length > 0;

      if (typeof consumablesForFilm?.exclusive === 'boolean') {
        relatedState.is_exclusive = consumablesForFilm?.exclusive;
      }
    }

    return relatedState;
  };

  const doTrackSnowplowQuickSearchEvent = async (
    eventData,
    relatedIds: ObjectOfAny = {},
    extraData: ObjectOfAny = {},
  ) => {
    const pageType = await getSnowplowPageType(router, vanityPathPageType);
    const relatedState = await getRelatedState(relatedIds);
    trackSnowplowQuickSearchEvent(
      {
        ...pageType,
        ...eventData,
        ...relatedState,
        ...extraData,
        country_code: countryCode,
        language: router.locale,
      },
      httpContext,
    );
  };

  return doTrackSnowplowQuickSearchEvent;
};

export default useSnowplowQuickSearchTracking;
