import { Notification } from '@app/api/resources/Notifications';

import * as actionTypes from '@app/actionTypes';

export const setNotifications = (notifications: Notification[]) => ({
  type: actionTypes.SET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});
