import { Fragment } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/FilmGroup';

import {
  CastMemberSearchResult,
  FilmSearchResult,
  getResultsToShowFromSearchResults,
  initialSearchResults,
  SearchResults,
} from '@app/api/services/search';

import useSnowplowQuickSearchTracking from '@app/hooks/snowplow/useSnowplowQuickSearchTracking';

import NavSearchCastMemberResult from '@app/components/layout/nav-search/NavSearchCastMemberResult';
import NavSearchCollectionResult from '@app/components/layout/nav-search/NavSearchCollectionResult';
import NavSearchFilmResult from '@app/components/layout/nav-search/NavSearchFilmResult';
import NavSearchNoFilmResult from '@app/components/layout/nav-search/NavSearchNoFilmResult';
import NavSearchResultsSeeAll from '@app/components/layout/nav-search/NavSearchResultsSeeAll';

type NavSearchResultsProps = {
  searchText: string;
  searchResults?: SearchResults;
  searchMeta: {
    filmTotalCount?: number;
    castMemberTotalCount?: number;
    collectionTotalCount?: number;
  };
  onShowAll: () => void;
  setHideResults?: (shouldHide: boolean) => void;
};
const NavSearchResults = ({
  searchText,
  searchResults = initialSearchResults,
  searchMeta,
  onShowAll,
  setHideResults,
}: NavSearchResultsProps) => {
  const { t } = useTranslation('common');
  const [resultObject, sortedResultKeys] = searchResults;
  const resultsToShow = getResultsToShowFromSearchResults(resultObject);

  const doTrackSnowplowQuickSearchEvent = useSnowplowQuickSearchTracking();

  const getFilmResults = () => {
    const { filmResults } = resultsToShow;

    return filmResults?.map((filmResult: FilmSearchResult, i: number) => (
      <div
        key={`film-result-${filmResult.id}`}
        onClick={() => {
          doTrackSnowplowQuickSearchEvent(
            {
              entity_id: filmResult.id,
              click_type: 'film',
            },
            {
              film_id: filmResult.id,
            },
            {
              search_rank: i + 1,
              search_term: searchText,
            },
          );
          setHideResults(true);
        }}
      >
        <NavSearchFilmResult filmResult={filmResult} />
      </div>
    ));
  };

  const renderFilmResults = () => {
    const { filmTotalCount } = searchMeta;
    if (filmTotalCount > 0) {
      return (
        <div>
          <SearchResultTitle>
            {t('common:common.search_results.films')}&nbsp;
            <SearchResultTitleCount>
              {searchMeta.filmTotalCount}
            </SearchResultTitleCount>
          </SearchResultTitle>
          {getFilmResults()}
        </div>
      );
    } else {
      return <NavSearchNoFilmResult />;
    }
  };

  const getCastMemberResults = () => {
    const { castMemberTotalCount } = searchMeta;
    const { castMemberResults } = resultsToShow;
    if (castMemberTotalCount > 0) {
      return castMemberResults.map(
        (castMemberResult: CastMemberSearchResult, i: number) => (
          <div
            key={`castcrew-result-${castMemberResult.id}`}
            onClick={() => {
              doTrackSnowplowQuickSearchEvent(
                {
                  entity_id: castMemberResult.id,
                  click_type: 'cast_crew',
                },
                {},
                {
                  search_rank: i + 1,
                  search_term: searchText,
                },
              );
              setHideResults(true);
            }}
          >
            <NavSearchCastMemberResult castMemberResult={castMemberResult} />
          </div>
        ),
      );
    }
    return null;
  };

  const renderCastMemberResults = () =>
    searchMeta.castMemberTotalCount > 0 && (
      <div>
        <SearchResultTitle>
          {t('common:common.search_results.cast_members')}&nbsp;
          <SearchResultTitleCount>
            {searchMeta.castMemberTotalCount}
          </SearchResultTitleCount>
        </SearchResultTitle>
        {getCastMemberResults()}
      </div>
    );

  const getCollectionResults = () => {
    const { collectionTotalCount } = searchMeta;
    const { collectionResults } = resultsToShow;
    if (collectionTotalCount > 0) {
      return collectionResults.map(
        (collectionResult: Collection, i: number) => (
          <div
            key={`collection-result-${collectionResult.id}`}
            onClick={() => {
              doTrackSnowplowQuickSearchEvent(
                {
                  entity_id: collectionResult.id,
                  click_type: 'collection',
                },
                {},
                {
                  search_rank: i + 1,
                  search_term: searchText,
                },
              );
              setHideResults(true);
            }}
          >
            <NavSearchCollectionResult collectionResult={collectionResult} />
          </div>
        ),
      );
    }
    return null;
  };

  const renderCollectionResults = () =>
    searchMeta.collectionTotalCount > 0 && (
      <div>
        <SearchResultTitle>
          {t('common:common.search_results.collections')}
        </SearchResultTitle>
        {getCollectionResults()}
      </div>
    );

  const mapSearchResult = {
    films: renderFilmResults(),
    film_groups: renderCollectionResults(),
    cast_members: renderCastMemberResults(),
  };

  return (
    <Container>
      {sortedResultKeys.map(key => (
        <Fragment key={key}>{mapSearchResult[key]}</Fragment>
      ))}
      <NavSearchResultsSeeAll searchText={searchText} onShowAll={onShowAll} />
    </Container>
  );
};

export default NavSearchResults;

const Container = styled.div`
  background-color: white;
  width: 100%;
`;

export const SearchResultTitle = styled.div`
  font-family: ${props => props.theme.font.title};
  font-weight: 500;
  color: ${props => props.theme.color.midGray};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-top: 18px;
  margin-left: 15px;
  padding-bottom: 7px;
`;

const SearchResultTitleCount = styled.span`
  font-weight: normal;
`;
