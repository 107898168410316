import { ReactNode } from 'react';
import styled from '@emotion/styled';

import Link from '@app/components/Link';

type GoLinkProps = {
  children: ReactNode;
};

const GoLink = ({ children }: GoLinkProps) => {
  return <CustomGoLink href="/go">{children}</CustomGoLink>;
};

export default GoLink;

const CustomGoLink = styled(Link)`
  text-decoration: none;
  &:visited {
  }
  &:hover {
    text-decoration: none;
  }
`;
