import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import differenceInDays from 'date-fns/differenceInDays';
import styled from '@emotion/styled';

import { Notification } from '@app/api/resources/Notifications';
import { Translate } from '@app/api/utility-types';

import { formatDate, formatDistanceDate } from '@app/services/date-format';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import ImageBase from '@app/components/ImageBase';

const NotificationElement = ({
  notification,
}: {
  notification: Notification;
}) => {
  const { t, lang } = useTranslation('common');
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const notificationDate = useMemo(
    () => getNotificationDate(notification, t, lang),
    [notification, t],
  );

  const onNotificationClick = () => {
    trackSnowplowClickEvent({
      data: {
        clickType: 'notification',
        element: `${notification.type}_notification`,
      },
    });
  };

  return (
    <div onClick={onNotificationClick}>
      <NotificationLink href={notification.path} isRead={notification.read}>
        <NotificationInner>
          <NotificationImageContainer>
            <NotificationImage src={notification.image_url} />
          </NotificationImageContainer>

          <NotificationStory>
            <Body
              dangerouslySetInnerHTML={{ __html: notification.body_html }}
            />
            <NotificationMeta>{notificationDate}</NotificationMeta>
            {notification.preview && (
              <NotificationPreview>{notification.preview}</NotificationPreview>
            )}
          </NotificationStory>
        </NotificationInner>
      </NotificationLink>
    </div>
  );
};

const getNotificationDate = (
  notification: Notification,
  t: Translate,
  locale: string,
): string => {
  const createdAtDate = new Date(notification.created_at * 1000);
  const daysSinceNotification = differenceInDays(new Date(), createdAtDate);

  if (daysSinceNotification > 1) {
    return formatDate(createdAtDate, 'dd MMMM yyyy', locale);
  }
  return formatDistanceDate(t, createdAtDate, locale);
};

const NotificationLink = styled.a<{ isRead: boolean }>`
  display: block;
  outline: none;
  overflow: hidden;
  color: ${props => props.theme.color.darkText};
  padding: 13px 15px 0 15px;
  position: relative;
  font-size: 13px;

  background-color: ${props =>
    props.isRead ? props.theme.color.white : props.theme.color.midBackground};

  &:visited {
    color: ${props => props.theme.color.darkText};
  }
  &:hover {
    background-color: ${props => props.theme.color.lightBackground};
    color: ${props => props.theme.color.darkText};
  }
`;

const NotificationInner = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.lightGray};
  padding-bottom: 10px;
  overflow: hidden;
`;

const NotificationImageContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  overflow: hidden;
`;

const NotificationImage = styled(ImageBase)`
  height: 100%;
  object-fit: cover;
  display: block;
`;

const NotificationStory = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 55px);
  white-space: normal;
`;
const Body = styled.div`
  line-height: 1.3;

  .notification-header {
    font-family: ${props => props.theme.font.body};
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.color.darkText};
  }

  .notification-publisher {
    font-family: ${props => props.theme.font.body};
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.color.darkText};
  }

  .notification-prompt {
    font-family: ${props => props.theme.font.body};
    color: ${props => props.theme.color.mubiBlue};
  }
`;
const NotificationMeta = styled.div`
  margin-top: 3px;
  color: ${props => props.theme.color.midGray};
  font-size: 12px;
`;

const NotificationPreview = styled.div`
  font-size: 11px;
  padding-top: 10px;
  margin-top: 10px;
  color: ${props => props.theme.color.lightText};
  border-top: 1px solid ${props => props.theme.color.lightGray};
`;

export default NotificationElement;
