import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import useSnowplowQuickSearchTracking from '@app/hooks/snowplow/useSnowplowQuickSearchTracking';

import Link from '@app/components/Link';

type NavSearchResultsSeeAllProps = {
  searchText: string;
  onShowAll: () => void;
};
const NavSearchResultsSeeAll = ({
  searchText,
  onShowAll,
}: NavSearchResultsSeeAllProps) => {
  const { t } = useTranslation('common');
  const doTrackSnowplowQuickSearchEvent = useSnowplowQuickSearchTracking();

  return (
    <div
      onClick={() => {
        doTrackSnowplowQuickSearchEvent({
          click_type: 'see_all',
        });
        onShowAll();
      }}
    >
      <SeeAllLink href={`/search/films?query=${searchText}`}>
        {t('common:common.search_results.see_all')}
      </SeeAllLink>
    </div>
  );
};

export default NavSearchResultsSeeAll;

const SeeAllLink = styled(Link)`
  font-family: ${props => props.theme.font.title};
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  padding: 15px 0;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.color.darkText};
  border-top: 1px solid ${props => props.theme.color.midBackground};
  margin-top: 17px;

  &:visited {
    color: ${props => props.theme.color.darkText};
  }

  &:hover {
    background: ${props => props.theme.color.lightBackground};
    color: ${props => props.theme.color.mubiBlue};
  }
`;
