import { useEffect, useState } from 'react';

import { Film } from '@app/api/resources/Film';

import { getSearchSuggestions } from '@app/api/services/search';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetSearchSuggestions = (): [Film[] | null, boolean] => {
  const [isError, setIsError] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState(null);

  const httpContext = useAppSelector(state => state.appState.httpContext);

  const doGetSearchSuggestions = async () => {
    try {
      const response = await getSearchSuggestions({
        httpContext,
      });
      setSearchSuggestions(response.data);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    doGetSearchSuggestions();
  }, []);

  return [searchSuggestions, isError];
};
export default useGetSearchSuggestions;
