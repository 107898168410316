import { SyntheticEvent } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { getMubiShopUrl } from '@app/services/promo-utils';

import useBrowseFilmsUrl from '@app/hooks/helpers/useBrowseFilmsUrl';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import MenuItem from '@app/components/layout/menu-links/MenuItem';
import {
  MenuHardLink,
  MenuLink,
} from '@app/components/layout/menu-links/MenuLink';
import MenuLinkButton from '@app/components/layout/menu-links/MenuLinkButton';

type MenuLinksLoggedOutProps = {
  geoLocation?: string;
  showHelpScoutPopUp: (e: SyntheticEvent) => void;
};

const MenuLinksLoggedOut = ({
  showHelpScoutPopUp,
  geoLocation = null,
}: MenuLinksLoggedOutProps) => {
  const { t } = useTranslation('common');
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const browseFilmsUrl = useBrowseFilmsUrl();

  return (
    <>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_browse' } })
        }
      >
        <MenuLink href={browseFilmsUrl}>
          {t('common:common.subheader_nav.browse')}
        </MenuLink>
      </MenuItem>
      {(geoLocation === 'GB' || geoLocation === 'US') && (
        <MenuItem
          onClick={() =>
            trackSnowplowClickEvent({ data: { clickType: 'menu_shop' } })
          }
        >
          <MenuHardLink href={getMubiShopUrl(geoLocation)}>SHOP</MenuHardLink>
        </MenuItem>
      )}
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_gift_mubi' } })
        }
      >
        <MenuLink href="/gifts">
          {t('common:common.subheader_nav.gift')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_about' } })
        }
      >
        <MenuLink href="/about">
          {t('common:common.subheader_nav.about')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_ways_to_watch' } })
        }
      >
        <MenuLink href="/ways-to-watch">
          {t('common:common.subheader_nav.ways_to_watch')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_students' } })
        }
      >
        <MenuLink href="/student">
          {t('common:common.subheader_nav.students')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_jobs' } })
        }
      >
        <MenuHardLink href={process.env.JOBS_SITE_URL}>
          {t('common:common.subheader_nav.jobs')}
        </MenuHardLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_terms' } })
        }
      >
        <MenuLink href="/terms_of_service">
          {t('common:common.subheader_nav.terms')}
        </MenuLink>
      </MenuItem>
      <MenuItem
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'menu_help' } })
        }
      >
        <MenuLinkButton onClick={showHelpScoutPopUp}>
          {t('common:common.subheader_nav.help')}
        </MenuLinkButton>
      </MenuItem>
    </>
  );
};

export default MenuLinksLoggedOut;
