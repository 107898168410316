import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { CurrentUser } from '@app/api/resources/User';

import { getImageUrl } from '@app/services/images';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import ImageBase from '@app/components/ImageBase';
import NotificationDot from '@app/components/layout/notifications/NotificationDot';
import NotificationsContainer from '@app/components/layout/notifications/NotificationsContainer';
import Link from '@app/components/Link';

const DesktopLoggedInSection = ({
  user = {} as CurrentUser,
}: {
  user?: CurrentUser;
}) => {
  const { t } = useTranslation('common');
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const getUserImage = () => {
    if (user.avatar_url) {
      return `${user.avatar_url}?size=100x`;
    }
    return getImageUrl(process.env.defaultProfileImage);
  };

  return (
    <Container>
      <UserImageLink
        href={`/users/${user.id}`}
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'tab_profile' } })
        }
      >
        <UserImage
          src={getUserImage()}
          alt={t('common:common.header_nav.profile_pic_alt')}
          loading="eager"
        />
      </UserImageLink>

      <NotificationsContainer
        onClick={() =>
          trackSnowplowClickEvent({ data: { clickType: 'tab_notifications' } })
        }
      >
        {({ notifications }) => (
          <NotificationDot notifications={notifications} />
        )}
      </NotificationsContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    margin-right: 21px;
  }
`;

const UserImageLink = styled(Link)`
  display: inline-block;
`;

const UserImage = styled(ImageBase)`
  display: block;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 6px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export default DesktopLoggedInSection;
