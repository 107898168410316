import styled from '@emotion/styled';

import { getHeaderColor } from '@app/services/utils';

import { color as themeColors, ColorHex } from '@app/themes/mubi-theme';

import {
  MubiGoNewLogoIcon,
  MubiLogoIcon,
} from '@app/components/icons/LogoIcons';
import { HeaderVariant } from '@app/components/layout/HeaderContainer';
import Link from '@app/components/Link';

const MubiNavLogo = ({
  logoType,
  variant = 'default',
  isAuthenticated,
  color,
}: {
  isAuthenticated: boolean;
  logoType?: 'mubi' | 'go';
  variant?: HeaderVariant;
  color?: ColorHex;
}) => {
  const logoColor = getHeaderColor({
    variant,
    defaultColor: themeColors.black,
    fallbackColor: themeColors.white,
    overrideColor: color,
  });

  return (
    <LogoLink href={isAuthenticated ? '/showing' : '/'}>
      {logoType === 'go' ? (
        <LogoContainer>
          <MubiGoNewLogoIcon width="115px" color={logoColor} />
        </LogoContainer>
      ) : (
        <MubiLogoIcon
          width="75px"
          logoColor={logoColor}
          dotsColor={logoColor}
        />
      )}
    </LogoLink>
  );
};

export default MubiNavLogo;

const LogoLink = styled(Link)`
  display: block;
  text-decoration: none;
`;

const LogoContainer = styled.div`
  padding-top: 20px;
`;
