import { shallowEqual } from 'react-redux';

import { getSubscriptionPlansToOffer } from '@app/api/resources/SubscriptionPlans';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const LOCATION_HAS_MUBI_GO_FOR_REGULAR_SUBSCRIBERS: string[] = [];

export const useGetGoAvailabilityForCountry = (
  options: {
    selectCitiesOnly?: boolean;
    subscriptionPlansForUserOnly?: boolean;
  } = {
    selectCitiesOnly: false,
    subscriptionPlansForUserOnly: false,
  },
): {
  isPremiumPlanInCountry: boolean;
  isPremiumIncludedInGift: boolean;
  isGoIncludedInBasicPlan: boolean;
} => {
  const {
    geoLocation,
    closeToUSCity,
    subscriptionPlansForAllInCountry,
    subscriptionPlansForUser,
  } = useAppSelector(
    state => ({
      geoLocation: state.user.geoLocation,
      closeToUSCity: !!state.appState.onboardingConfig?.closeToUSCity,
      subscriptionPlansForAllInCountry: state.user.subscriptionPlans,
      subscriptionPlansForUser: state.user.subscriptionPlansForUser,
    }),
    shallowEqual,
  );

  const getSubscriptionPlans = () => {
    if (options?.subscriptionPlansForUserOnly) {
      return getSubscriptionPlansToOffer(
        subscriptionPlansForAllInCountry,
        subscriptionPlansForUser,
      );
    }

    return subscriptionPlansForAllInCountry;
  };

  const subscriptionPlans = getSubscriptionPlans();

  const isPremiumPlanInCountry = () => {
    if (geoLocation === 'US' && options?.selectCitiesOnly) {
      return closeToUSCity;
    }

    return Boolean(
      subscriptionPlans?.month_premium || subscriptionPlans?.year_premium,
    );
  };

  const isGoIncludedInBasicPlan =
    LOCATION_HAS_MUBI_GO_FOR_REGULAR_SUBSCRIBERS.includes(geoLocation);

  const isPremiumIncludedInGift = ['GB'].includes(geoLocation);

  return {
    isPremiumPlanInCountry: isPremiumPlanInCountry(),
    isPremiumIncludedInGift,
    isGoIncludedInBasicPlan,
  };
};
