import useTranslation from 'next-translate/useTranslation';

import { Collection } from '@app/api/resources/FilmGroup';

import { stripDomainFromUrl } from '@app/services/routeHelpers';

import NavSearchPictureResult from '@app/components/layout/nav-search/NavSearchPictureResult';

const NavSearchCollectionResult = ({
  collectionResult,
}: {
  collectionResult: Collection;
}) => {
  const { t } = useTranslation('common');
  const getImage = (collection: Collection) =>
    collection?.image_urls?.square ?? '';

  const { full_title, canonical_url, film_count, is_series } = collectionResult;

  const collectionSubtitle = t(
    `common:common.header_nav.collection_total_${
      is_series ? 'episodes' : 'films'
    }${film_count === 1 ? '' : '_other'}`,
    {
      count: film_count,
    },
  );

  return (
    <NavSearchPictureResult
      imageUrl={getImage(collectionResult)}
      title={full_title}
      subtitle={collectionSubtitle}
      resultUrl={stripDomainFromUrl(canonical_url)}
      numTitleLines={2}
    />
  );
};

export default NavSearchCollectionResult;
